import Axios from 'axios';
import NProgress from 'nprogress';

function Req ({type, data, headers, query}){
    const baseUrl = `/api/v1`;
    const [method, url] = URLParser(type);
    const token = localStorage.getItem('token');
    Axios.interceptors.request.use((config) => {
        if(config){
            config.methods.setLoading(true)
        }
        return config;
    }, (error) => {
        error.response.methods.setLoading(false)
        return Promise.reject(error);
    });
    Axios.interceptors.response.use((response) => {
        if(response && response.config){
            response.config.methods.setLoading(false);
        }
        return response;
    }, (error) => {
        if(error && error.response && error.response.config){
            error.response.config.methods.setLoading(false);
        }
        return Promise.reject(error);
    });
    return Axios({
        url: `${baseUrl}/${url}`,
        method: method ? method : "GET",
        data: data ? data : {},
        params: query  && Object.keys(query).length ? query : {},
        headers: headers ? {
            ...headers,
            Authorization: token
        } : {
            Authorization: token
        },
        methods: {
            setLoading(isLoading) {
                if(isLoading){
                    NProgress.start()
                }else{
                    NProgress.done()
                }
            }
        }
    });
}

function URLParser(string){
    return string.split(' ');
}
async function Request(type, data,headers){
    try{
        const res = await Req(type,data,headers);
        return res.data;
    }catch (err){
        return {
            content: err.response?.statusText,
            type: false,
            status: err.response?.status
        }
    }
}
export default Req;
export {Req, URLParser, Request};
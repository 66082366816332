import {Button, Card, Col, Row} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined} from "@ant-design/icons";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import AppTable from "../../../../../../components/Table";
import {connect, useDispatch} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import Req from "../../../../../../store/api";
import {CLIENT_GET_FIELDS, CLIENT_REMOVE_FIELD} from "../../../../../../store/types";

function ListData({reload = null}){
    const {operatorId,categoryId,childCategoryId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [columns,setColumns] = useState([
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '5%'
        },
        {
            title: 'Sarlavha (Uz)',
            dataIndex: 'titleUz',
            key: 'titleUz',
            render: (_,item)=> item.content?.titleUz
        },
        {
            title: 'Yoqish kodi',
            dataIndex: 'activateCode',
            key: 'activateCode',
        },
        {
            title: `O'chirish kodi`,
            dataIndex: 'deActivateCode',
            key: 'deActivateCode',
        },
        {
            title: `Tekshirish kodi`,
            dataIndex: 'check',
            key: 'check',
        },
        {
            title: 'Amallar',
            width: '20%',
            dataIndex: 'address',
            render:(_, field) => {
                return <div>
                    <Button onClick={()=>remove(field.id)} type='danger' className="mr-2">
                        <DeleteOutlined /> O'chirish
                    </Button>
                    <Link to={`/client/category/${operatorId}/${categoryId}/${childCategoryId}/${field.id}`} className='ant-btn ant-btn-primary'>
                        <EditOutlined /> O'zgartirish
                    </Link>
                </div>
            }
        },
    ]);
    const [loader,setLoader] = useState(false);
    useEffect(()=>{
        if(childCategoryId){
            setLoader(true);
            Req({
                type: CLIENT_GET_FIELDS,
                query:{categoryId: childCategoryId}
            })
                .then(res=>{
                    setData(res.data.content);
                    setLoader(false);
                })
                .catch(err=>{
                    navigate(`/client/category/${operatorId}/${categoryId}`)
                    setLoader(false);
                })
        }
    },[location,reload]);
    const remove = (id)=>{
        dispatch({
            type: CLIENT_REMOVE_FIELD,
            payload: id
        })
    }
    const table = useMemo(()=>{
        return  (
            <Row className='py-4'>
                <Col span={24}>
                    <AppTable
                        columns={columns}
                        data={data}
                        loading={loader}
                    />
                </Col>
            </Row>
        )
    },[location,loader]);
    return (
        <Card className="my-3">
            <Row justify='end'>
                <Col className="text-right" span={12}>
                    <Link to={`/client/category/${operatorId}/${categoryId}/${childCategoryId}/add`} className="ant-btn ant-btn-primary">
                        <PlusOutlined />Yangi qo'shish
                    </Link>
                </Col>
            </Row>
            {table}
        </Card>
    )
}
export default connect(s=>{
    return{
        reload: s?.reload
    }
})(ListData);
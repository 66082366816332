import {Col, Form, Input, Row} from "antd";
import Editor from "../../../../../../components/Editor";
import {useDispatch} from "react-redux";

function FormTab({type = 'Uz',editor= null}){
    const dispatch = useDispatch();
    const changeEditor = (v)=>{
        dispatch({
            type: `editor${type}`,
            payload: v
        });
    }
    return  <>
                <Form.Item
                    name={`title${type}`}
                    label={`Sarlavha (${type})`}
                    rules={[
                        {
                            required: true,
                            message: `Sarlavhani kiriting (${type})`
                        }
                    ]}
                >
                    <Input placeholder="Sarlavha matnini kiriting"/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={`mb${type}`}
                            label={`Internet trafik (${type})`}
                        >
                            <Input placeholder="Internet trafik: 30 Mb yoki 1 Gb"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={`sms${type}`}
                            label={`SMS paketlar (${type})`}
                        >
                            <Input placeholder="SMS paketlar: 100 ta sms"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={`minutes${type}`}
                            label={`Qaqiqalar soni (${type})`}
                        >
                            <Input placeholder="Qaqiqalar soni: 30 min yoki 1 soat"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name={`lifeTime${type}`}
                    label={`Amal qilish muddati (${type})`}
                >
                    <Input placeholder="Amal qilish muddatini kiriting. Misol uchun: 35 kun"/>
                </Form.Item>
                <Editor value={editor} onChange={changeEditor} type={type} title={`Qo'shimcha ma'lumot ${type}`}/>
            </>
}
export default FormTab
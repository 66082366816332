import {Button, Form, Input, Modal} from "antd";
import {useDispatch} from "react-redux";
import {ADD_COLOR_TO_USER_OPERATOR, GET_USER_OPERATORS} from "../../../../../store/types";
import Req from "../../../../../store/api";
import {useEffect, useState} from "react";
import Loader from "../../../../../components/Loader";
import {Link} from "react-router-dom";

function SetOperatorColor({operator,isShow,onClose}){
    const dispatch = useDispatch();
    const [data,setData] = useState([]);
    const [loader,setLoader] = useState(true);
    useEffect(()=>{
        if(Object.keys(operator).length){
            Req({
                type: GET_USER_OPERATORS,
                query:{
                    operatorId: operator.id,
                    userId: operator.userId
                }
            }).then(res=>{
                setData(res.data.content);
                setLoader(false);
            }).catch(err=>{
                setLoader(false);
            })
        }
    },[operator])
    const onFinish = (v)=>{
        Req({
            type: ADD_COLOR_TO_USER_OPERATOR,
            data: {
                ...v,
                operatorId: operator.id,
                userId: operator.userId
            }
        }).then(res=>{
            dispatch({
                type: 'toast',
                payload:{
                    type: 'success',
                }
            });
            onClose();
        }).catch(err=>{
            dispatch({
                type: 'toast',
                payload:{
                    type: 'error',
                    message: "Hatolik qayta urunib ko'ring!"
                }
            })
        })
    }
    return  <Modal
                    title="Foydalanuvchi tanlang!"
                    visible={isShow}
                    onCancel={onClose}
                    footer={null}
                >
                    {
                        loader ?
                            <Loader type='sm'/>:
                            <>
                                <p>{operator.titleUz}</p>
                                <Form
                                    initialValues={data}
                                    name='operator_add_color'
                                    onFinish={onFinish}
                                    layout='vertical'
                                >
                                    <Form.Item
                                        label='Dark color'
                                        name="colorDark"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Iltomos to'ldiring!"
                                            },
                                        ]}
                                    >
                                        <Input type='color'/>
                                    </Form.Item>
                                    <Form.Item
                                        label='Light color'
                                        name="colorLight"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Iltomos to'ldiring!"
                                            },
                                        ]}
                                    >
                                        <Input type='color'/>
                                    </Form.Item>
                                    <Form.Item
                                        label='Opacity color'
                                        name="colorOpacity"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Iltomos to'ldiring!"
                                            },
                                        ]}
                                    >
                                        <Input type='color'/>
                                    </Form.Item>
                                    <Form.Item className='text-right'>
                                        <Link to={`operator/${operator.id}/${operator.userId}`} className='ant-btn mr-2'>
                                            Kategoriyani sozlash
                                        </Link>
                                        <Button className='mr-2' onClick={onClose}>
                                            Bekor qilish
                                        </Button>
                                        <Button type='primary' htmlType='submit'>
                                            Saqlash
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                    }
                </Modal>
}
export default SetOperatorColor;
import {Button, Drawer, Form, Input} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import Req from "../../../../store/api";
import {CLIENT_ADD_CATEGORY, CLIENT_UPDATE_CATEGORY} from "../../../../store/types";
import {connect, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

function AddCategory({visible = false, dividerCategory = {}}){
    const {categoryId,operatorId} = useParams();
    const dispatch =useDispatch();
    const onReload = ()=>{
        dispatch({
            type: 'reload',
            payload: Math.random()
        })
    }
    const onClose = ()=>{
        dispatch({
            type: 'visible',
            payload: false
        });
    }
    const onFinish = (v)=>{
        Req({
            type: Object.keys(dividerCategory).length ? `${CLIENT_UPDATE_CATEGORY}/${dividerCategory.id}` :CLIENT_ADD_CATEGORY,
            data: v
        })
            .then(res=>{
                dispatch({
                    type: 'toast',
                    payload: {
                        type: 'success',
                        message: `Qo'shildi!`
                    }
                });
                dispatch({
                    type: 'dividerCategory',
                    payload: {}
                })
                onClose();
                onReload();
            })
            .catch(err=>{
                console.log(err);
                dispatch({
                    type: 'toast',
                    payload: {
                        type: 'error',
                        message: `Hatolik qayta urunib ko'ring!`
                    }
                })
            })
    }
    return <Drawer title="Yangi kategoriya qo'shish" placement="right" onClose={onClose} visible={visible}>
                <Form
                    onFinish={onFinish}
                    name='addCategory'
                    layout='vertical'
                    initialValues={dividerCategory}
                >
                    <Form.Item
                        name='titleUz'
                        label='Nomi Uz'
                        rules={[{
                            required: true,
                            message: `Iltimos to'ldiring`
                        }]}
                    >
                        <Input placeholder='Ixtiyoriy matin'/>
                    </Form.Item>
                    <Form.Item
                        name='titleRu'
                        label='Nomi Ru'
                        rules={[{
                            required: true,
                            message: `Iltimos to'ldiring`
                        }]}
                    >
                        <Input placeholder='Ixtiyoriy matin'/>
                    </Form.Item>
                    <Form.Item
                        name='titleEn'
                        label='Nomi En'
                        rules={[{
                            required: true,
                            message: `Iltimos to'ldiring`
                        }]}
                    >
                        <Input placeholder='Ixtiyoriy matin'/>
                    </Form.Item>
                    <Form.Item
                        name='order'
                        label='Tartibi'
                        rules={[{
                            required: true,
                            message: `Iltimos to'ldiring`
                        }]}
                    >
                        <Input type='number' placeholder='Tartib soni'/>
                    </Form.Item>
                    <Form.Item
                        hidden={true}
                        name='parentId'
                        initialValue={categoryId}
                    />
                    <Form.Item
                        hidden={true}
                        name='operatorId'
                        initialValue={operatorId}
                    />
                    <Form.Item className="text-right">
                        <Button onClick={onClose} type='danger' className="mr-2">
                            <CloseOutlined /> Bekor qilish
                        </Button>
                        <Button type='primary' htmlType='submit'>
                            <SaveOutlined/> Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
}
export default connect(
    s=>{
        return {
            visible: s?.visible,
            dividerCategory: s?.dividerCategory
        }
    }
)(AddCategory);
import {Button, Card, Col, Form, Input, message, Row, Select, Upload} from "antd";
import {LoadingOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
    CREATE_MAIN_CATEGORY_LOGO_BY_USER,
    GET_CATEGORY,
    GET_MAIN_CATEGORY_LOGO_BY_USER,
    REMOVE_FILE
} from "../../../../store/types";
import {useDispatch, useSelector} from "react-redux";
import {Option} from "antd/es/mentions";
import Req from "../../../../store/api";
import axios from "axios";
import logo from "../../../../components/Logo";


function DashboardUserCategory() {
    const {operatorId, userId} = useParams();
    const { categories = [], uploadImage = []}= useSelector(s=>s);
    const dispatch = useDispatch();
    useEffect(()=>{
       Req({
           type: `${GET_MAIN_CATEGORY_LOGO_BY_USER}${userId}`,
           query:{
               operatorId
           }
       })
           .then(res=>{
               const filter = res.data.content.map(v=>(
                   {
                       categoryId: v.categoryId,
                       fileId: v.fileId,
                       file: v.file
                   }
               ))
               dispatch({
                   type: 'uploadImage',
                   payload: filter
               });
           })
        dispatch({
            type: GET_CATEGORY,
            payload:{
                q: 'isParent'
            }
        })
    },[]);
    return (
        <div>
            <Row gutter={16}>
                {
                    categories.map(c=>{
                        return(
                            <Col span={6} key={`operator${c.id}`}>
                                <Card>
                                    <div className="mb-3">
                                        <strong>Kategoriya nomi: {c.titleUz}</strong>
                                    </div>
                                    <File id={c.id}/>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}
const File = ({id = null})=>{
    const {uploadImage= []} = useSelector(s=>s);
    const {operatorId, userId} = useParams();
    const [value,setValue] = useState(null);
    useEffect(()=>{
        if(uploadImage.length){
            const filter = uploadImage.find(v=>{
                return  v.categoryId === id
            });
            if(filter) {
                setValue(filter)
            }
        }
    },[uploadImage]);
    const fileInput = useRef()
    const onChange = ()=> {
        const file = fileInput.current.files[0];
        const formData = new FormData();
        formData.append('file', file);
        Req({
            type: value ? `put file/${value.id}` : 'post file',
            data: formData,
            headers: {
                'Content-Type':'multipart/form-data',
            }
        })
            .then(res=>{
                setValue({
                    categoryId: id,
                    fileId: res.data.content.id,
                    file: res.data.content
                })
            })
            .catch(err=>{
                console.log(err);
                // message.error(`Qayta urinib ko'ring!`);
            })
    }
    const onSave = ()=>{
        const data = {
            fileId: value.fileId,
            userId: userId,
            categoryId: id,
            operatorId: operatorId
        }
        Req({
            type: CREATE_MAIN_CATEGORY_LOGO_BY_USER,
            data: data
        })
            .then(res=>{
                message.success('Saqlandi')
                console.log(res);
            })
            .catch(err=>{
                console.log(err);
                message.error(`Qayta urinib ko'ring!`);
            })
    };
    return(
        useMemo(()=>{
            return (
                <>
                    <div className="mb-3">
                        <div className="ant-btn mb-3" style={{height: 'auto'}}>
                            <input ref={fileInput} onChange={onChange} type='file'/>
                        </div>
                        {
                            value && value.file ?
                                <div>
                                    <img className="img-fluid" src={`${process.env.REACT_APP_SERVER_URL}/${value.file.url}`} alt=""/>
                                </div>
                                :''
                        }
                    </div>
                    <div className="mb-3">
                        <Button onClick={onSave} block>
                            Saqlash
                        </Button>
                    </div>
                </>
            )
        },[value])
    )
}

export default DashboardUserCategory;
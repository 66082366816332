import {GET_FIELDS, REMOVE_FIELDS} from "../../../store/types";
import {Link, Outlet, useOutlet} from "react-router-dom";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Card, Col, Row} from "antd";
import {connect, useDispatch} from "react-redux";
import AppTable from "../../../components/Table";
import {useState} from "react";

function Fields(props){
    const {fields=[]} = props;
    const [columns, setColumns] = useState([
        {
            title: 'id',
            width: '4%',
            dataIndex: 'id',
            render: (_,record)=>{
                return record.id
            }
        },{
            title: 'Sarlavha',
            dataIndex: 'titleUz',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.content?.titleUz}</span>
                )
            }
        },{
            title: 'Aktivlash kodi',
            dataIndex: 'activateCode',
        },{
            title: `O'chirish kodi`,
            dataIndex: 'deActivateCode',
        },{
            title: 'Operation',
            dataIndex: 'operation',
            width: '15%',
            render: (_, record) => {
                return(
                    <div className="text-center">
                        <Link to={`/dashboard/fields/${record.id}`}  className="ant-btn ant-btn-primary mr-2 ant-btn-sm">
                            <EditOutlined/> Edit
                        </Link>
                        <Button type='danger' size="small" onClick={()=>onRemove(record.id)}>
                            <DeleteOutlined/> Remove
                        </Button>
                    </div>
                )
            },
        },
    ]);
    const dispatch = useDispatch();
    const onRemove =(id)=>{
        dispatch({
            type: REMOVE_FIELDS,
            payload: id
        })
    }
    const outlet = useOutlet();
    return(
        <Card>
            {
                outlet ?
                    <Outlet/>:
                    <>
                        <Row justify='between' className="mb-3">
                            <Col span={12}>
                                <h1>Fields</h1>
                            </Col>
                            <Col span={12} className="text-right">
                                <Link to='/dashboard/fields/add' className="ant-btn ant-btn-primary">
                                    <PlusCircleOutlined/> Add
                                </Link>
                            </Col>
                        </Row>
                        <AppTable
                            type={GET_FIELDS}
                            data={fields}
                            columns={columns}
                        />
                    </>
            }

        </Card>
    )
}
export default connect((state)=>{
    return {
        fields: state?.fields
    }
})(Fields);
import {Col, Row} from "antd";
import ClientUser from "../clientUser";

function AppHeader(props){

    return  <Row justify='end'>
                <Col span={6} className="text-right">
                    <ClientUser/>
                </Col>
            </Row>
}
export default AppHeader;
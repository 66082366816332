import {useParams} from "react-router-dom";

function ProductsSingle(props){
    const query = useParams();
    console.log(query)
    return(
        <div>
            <h3>Single product</h3>
        </div>
    )
}
export default ProductsSingle;
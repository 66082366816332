import {Button, Col, Form, Input, message, Row, Upload} from "antd";
import {ArrowLeftOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {GET_ONE_USER, UPDATE_USER} from "../../../../store/types";
import Loader from "../../../../components/Loader";


function UpdateUser(props) {
    const {user = {}} = props;
    const [loading,setLoading] = useState(false);
    const [imageUrl, setImage] = useState(null);
    const {id} = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(id){
            dispatch({
                type: GET_ONE_USER,
                payload: id
            })
        }
    },[id]);
    useEffect(()=>{
        if(Object.keys(user).length){
            setImage(user.file);
        }
    },[user]);
    const onFinish = (value) => {
        dispatch({
            type: UPDATE_USER,
            payload:{
                ...value,
                id: id,
                key:'user',
                fileId: imageUrl ? imageUrl.id : null
            }
        })
    }
    const beforeUpload = (file)=>{
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = info => {
        const {file} = info;
        console.log(info)
        if (file.status === 'uploading') {
            setLoading(true);
        }else{
            if(file.status === 'done'){
                setImage(file.response.content);
            }
            setLoading(false);
        }
        return null;
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        Object.keys(user).length ?
            <Form
                name='userAdd'
                onFinish={onFinish}
                initialValues={{...user}}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="First name"
                            name="firstName"
                            rules={[{required: true, message: `Pleas enter content`}]}>
                            <Input placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Last name"
                            name="lastName"
                            rules={[{required: true, message: `Pleas enter content`}]}
                        >
                            <Input placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Phone number"
                            name="phoneNumber"
                            rules={[
                                {required: true, message: `Pleas enter content`},
                                {
                                    pattern: /^[0-9]+$/,
                                    message: 'Name can only include letters and numbers.',
                                }
                            ]}
                        >
                            <Input placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Login"
                            name="login"
                        >
                            <Input disabled={true} placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="User password"
                            name="password"
                        >
                            <Input.Password
                                disabled={true}
                                placeholder="Input password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="App token"
                            name="appToken"
                        >
                            <Input placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Instagram url"
                            name="inst"
                        >
                            <Input type='url' placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Facebook url"
                            name="fb"
                        >
                            <Input type='url' placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Telegram url"
                            name="tg"
                        >
                            <Input type='url' placeholder="Input placeholder"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mt-3' label="User avatar">
                            <Upload
                                name="file"
                                accept="image/*"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`/api/v1/file${imageUrl ? `/${imageUrl.id}`:''}`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={`${process.env.REACT_APP_SERVER_URL}/${imageUrl.url}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className="text-right mt-3">
                    <Link to='/dashboard/users' className="ant-btn ant-btn-danger mr-3">
                        <ArrowLeftOutlined /> Back
                    </Link>
                    <Button type="primary" htmlType='submit'>
                        <PlusOutlined/> Save
                    </Button>
                </Form.Item>
            </Form>:
            <Loader type={'sm'}/>
    )
}

export default connect(
    (state)=> {
        return {user: state?.user}
    }
)(UpdateUser);
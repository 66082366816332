import Style from "styled-components";

const Div = Style.div`
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 3rem;
`
const Message = Style.h1`
    font-size: 35px;
    color:rgba(0,0,0,.4);
`
const Title = Style.h1`
    font-size: 120px;
    margin: 0;
`

function DashboardNotFound(props){

    return(
        <Div>
            <Title>
                404
            </Title>
            <Message>This page not found</Message>
        </Div>
    )
}
export default DashboardNotFound;
import Style from "styled-components";
import {DownOutlined, KeyOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const UserDiv = Style.div`
    display:flex;
    align-items: center;
    cursor: pointer;
`
const UserImgOverlay = Style.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`
const UserImg = Style.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit:cover;
    border:1px solid #e2e2e2;
`
const UserTitle = Style.h4`
    margin: 0;
    font-size:18px;
    padding: 0 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 83%;
`
const DropdownWrapper = Style.div`
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 0.7rem 1rem;
`

function User({userName, img = null}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logOut = ()=>{
        localStorage.removeItem("token");
        dispatch({
            type: 'isUser',
            payload: null
        });
        dispatch({
            type: 'is_user',
            payload: false
        });
        navigate('/login');
    }
    const DropMenu =()=>{
            return  <DropdownWrapper>
                        <Button className="mb-2" style={{width: '100%'}}>
                            <KeyOutlined/> Parolni o'zgaritirsh
                        </Button>
                        <Button className="mb-2" onClick={logOut} style={{width: '100%'}}>
                            <LogoutOutlined /> Chiqish
                        </Button>
                    </DropdownWrapper>
    }
    return (
        <Dropdown overlay={DropMenu} trigger={['click']}>
            <UserDiv>
                <UserImgOverlay>
                    {
                        img ?
                            <UserImg src={`${process.env.REACT_APP_SERVER_URL}/${img}`}/>:
                            <UserOutlined />
                    }
                </UserImgOverlay>
                <UserTitle>
                    {userName} <DownOutlined />
                </UserTitle>
            </UserDiv>
        </Dropdown>
    )
}
export default User;
import {useEffect, useState} from "react";
import Req from "../../store/api";
import {GET_OPERATOR_BY_USER} from "../../store/types";

function useOperators(){
    const [operators,setOperators] = useState([]);
    useEffect(()=>{
        Req({
          type: GET_OPERATOR_BY_USER,
        })
            .then(res=>{
                setOperators(res.data.content);
            })
    },[]);
    return operators;
}
export default useOperators
import {Table} from 'antd';
import {connect, useDispatch} from "react-redux";
import {useEffect, useMemo, useState} from "react";
const AppTable = (props)=>{
    const {data, columns, type = null, tableCount = 0, reload, pagination = {}} = props;
    const dispatch = useDispatch();
    const {offset} = pagination;
    const tableChange = (pagination, filters, sorter)=>{
        dispatch({
            type: 'pagination',
            payload:{
                ...pagination,
                current: pagination.current,
                offset: pagination.current === 1 ? 1 : (pagination.current - 1) *10
            }
        })
    }
    useEffect(()=>{
        if(type){
            dispatch({
                type: type,
                payload:{
                    offset: offset
                }
            });
        }
    },[reload,offset]);
    useEffect(()=>{
        dispatch({
            type: 'pagination',
            payload:{
                ...pagination, total: tableCount
            }
        })
    },[tableCount]);
    const table = useMemo(()=>{
        return <Table
            {...props}
            onChange={tableChange}
            pagination={pagination}
            bordered
            dataSource={data}
            columns={columns}
        />
    },[data]);
    return table;
}
const propsToState = state => {
    return {
        tableCount: state.tableCount ? state.tableCount : 0,
        reload: state?.reload
    }
};
export default connect(propsToState, null)(AppTable);
import React, {useEffect, useState} from "react";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from 'suneditor-react';
import Style from "styled-components";

const TitleEditor = Style.p`
    margin: 1rem 0;
    font-weight: normal;
`

const Editor =({
                   title, onChange, value = null , type = 'Uz'
               })=>{
    const [middleValue, setValue] = useState(value);
    useEffect(()=>{
        onChange(middleValue,type);
    },[middleValue]);
    return  <div style={{marginBottom: '1rem'}}>
                <TitleEditor>{title}</TitleEditor>
                <SunEditor
                    className={"test"}
                    setOptions={{
                        height: 600,
                        buttonList: [
                            ['undo', 'redo'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat',
                                'fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list',],
                            ['table','link']
                        ]
                    }}
                    name={`description${type}`}
                    setContents={value}
                    width="100%"
                    placeholder="Ixtiyoriy matini kiriting..."
                    onChange={(value)=>setValue(value)}/>
            </div>
}
export default Editor;
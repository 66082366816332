import Style from "styled-components";

const Div = Style.div`
    width: 90%;
    height: 90%;
    background-color: #fff;
    mask-image: url('/images/logo-dashboard.png');
    mask-repeat: no-repeat;
    mask-size: 100%;
    -web-kit-mask-image: url('/images/logo-dashboard.png');
    -web-kit-mask-repeat: no-repeat;
    -web-kit-mask-size: 100%;

`
const LogoWrapper = Style.div`
    margin: 1rem auto;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #1890ff;
    display: flex;
    justify-content: center;
    align-items: center;
`
function Logo(props){
    return <LogoWrapper>
                <Div/>
            </LogoWrapper>
}
export default Logo;
import {Link, useParams} from "react-router-dom";
import {Button, Card, Col, Form, Input, Row, Select} from "antd";
import FormField from "../component/FormField";
import {connect, useDispatch} from "react-redux";
import {CLIENT_ADD_FIELDS} from "../../../../../store/types";
import {ArrowLeftOutlined, SaveOutlined} from "@ant-design/icons";


const {Option} = Select;
function AddField({editorUz,editorRu,editorEn}){
    const {operatorId,categoryId,childCategoryId} = useParams();
    const dispatch = useDispatch();
    const onFinish = (v)=>{
        dispatch({
            type: CLIENT_ADD_FIELDS,
            payload: {
                ...v,
                order: parseInt(v.order),
                descriptionUz:editorUz,
                descriptionRu:editorRu,
                descriptionEn:editorEn,
                categoryId: childCategoryId,
                redirectUrl: `/client/category/${operatorId}/${categoryId}/${childCategoryId}`
            }
        })
    }
    return  <Card className="my-4">
                <Form
                    name='field-form'
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <FormField create/>
                    <Form.Item className="text-right">
                        <Link to={`/client/category/${operatorId}/${categoryId}/${childCategoryId}`}
                              className="ant-btn ant-btn-danger mr-2"
                        >
                            <ArrowLeftOutlined /> Bekor qilish
                        </Link>
                        <Button type='primary' htmlType='submit'>
                            <SaveOutlined /> Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
}
export default connect(s=>{
    return{
        editorUz: s?.editorUz,
        editorRu: s?.editorRu,
        editorEn: s?.editorEn,
    }
})(AddField);
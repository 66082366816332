import {Button, Col, Form, Input, message, Row, Select, Upload} from "antd";
import {ArrowLeftOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {ADD_CATEGORY, GET_CATEGORY} from "../../../../store/types";

const {Option} = Select;
function CategoryAdd({categories = []}) {
    const [loading,setLoading] = useState(false);
    const [imageUrl, setImage] = useState(null);
    const dispatch = useDispatch();
    useEffect(()=>{
       if(!categories.length){
           dispatch({
               type: GET_CATEGORY,
               payload:{
                   q: 'isParent'
               }
           })
       }
    },[])
    const onFinish = (value) => {
        value.prefix = value.prefix.replaceAll(' ', "_");
        value.prefix = value.prefix.toLocaleString();
        dispatch({
            type: ADD_CATEGORY,
            payload:{
                ...value,
                fileUrl: imageUrl ? imageUrl.url : null,
                redirectUrl: '/dashboard/categories'
            }
        })
    }

    const beforeUpload = (file)=>{
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = info => {
        const {file} = info;
        if (file.status === 'uploading') {
            setLoading(true);
        }else{
            if(file.status === 'done'){
                setImage(file.response.content);
            }
            setLoading(false);
        }
        return null;
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <Form
            name='categoryAdd'
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Title uz"
                        name="titleUz"
                        rules={[{required: true, message: `Pleas enter content`}]}>
                        <Input placeholder="Input placeholder"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Title ru"
                        name="titleRu"
                        rules={[{required: true, message: `Pleas enter content`}]}>
                        <Input placeholder="Input placeholder"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Title en"
                        name="titleEn"
                        rules={[{required: true, message: `Pleas enter content`}]}>
                        <Input placeholder="Input placeholder"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Parent category"
                        name="parentId">
                        <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                categories.map(item=>{
                                    return <Option value={item.id} key={`cateogriy${item.id}`}>{item.titleUz}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tartib raqami"
                        name="order"
                        rules={[
                            {required: true, message: `Iltimos to'ldiring!`},
                        ]}>
                        <Input type='number' placeholder="Input placeholder"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Type"
                        name="prefix"
                        rules={[
                            {required: true, message: `Iltimos to'ldiring!`},
                        ]}>
                        <Input placeholder="Input placeholder"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className='mt-3' label="Sarlavha rasm">
                        <Upload
                            name="file"
                            accept="image/*"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`/api/v1/file`}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={`${process.env.REACT_APP_SERVER_URL}/${imageUrl.url}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>
                </Col>

            </Row>
            <Form.Item className="text-right mt-3">
                <Link to='/dashboard/categories' className="ant-btn ant-btn-danger mr-3">
                    <ArrowLeftOutlined /> Back
                </Link>
                <Button type="primary" htmlType='submit'>
                    <PlusOutlined/> Save
                </Button>
            </Form.Item>
        </Form>
    )
}

export default connect(
    (state)=>{
        return {
            categories: state?.categories
        }
    }
)(CategoryAdd);
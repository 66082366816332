import {Col, Form, Input, Row, Select, Tabs} from "antd";
import FormTab from "../FormTab";
import {connect} from "react-redux";

const {Option} = Select;
const {TabPane} = Tabs;
function FormField({editorUz,editorRu,editorEn, create}){
    return <Row gutter={16}>
                <Col span={24}>
                    <Tabs>
                        <TabPane tab="O'zbekcha" key={'uz'}>
                            <FormTab editor={create ? null : editorUz} type={'Uz'}/>
                        </TabPane>
                        <TabPane tab="Ruscha" key={'ru'}>
                            <FormTab editor={create ? null : editorRu} type={'Ru'}/>
                        </TabPane>
                        <TabPane tab="Inglizcha" key={'en'}>
                            <FormTab editor={create ? null : editorEn} type={'En'}/>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="activateCode"
                        label="Yoqish kodi"
                    >
                        <Input placeholder="Aktivlashtirish kodi"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="deActivateCode"
                        label="O'chirish kodi"
                    >
                        <Input placeholder="Deaktivlashtirish kodi"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="check"
                        label="Tekshirish uchun kodi"
                    >
                        <Input placeholder="Tekshirish kodi"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="price"
                        label="Narxi"
                    >
                        <Input placeholder="Tekshirish kodi"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="priceType"
                        label="To'lov turi"
                    >
                        <Select defaultValue={'UZS'}>
                            <Option value='UZS'>So'm</Option>
                            <Option value='USD'>AQSh dollari</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="order"
                        label="Tartibi"
                        defaultValue={1}
                    >
                        <Input type="number" placeholder="Tartib raqami"/>
                    </Form.Item>
                </Col>
            </Row>
}
export default connect((s)=>{
    return {
        editorUz: s?.editorUz,
        editorRu: s?.editorRu,
        editorEn: s?.editorEn,
    }
})(FormField);
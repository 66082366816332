import {CHANGE_STATUS_USER, GET_USERS} from "../../../store/types";
import {Link, Outlet, useOutlet} from "react-router-dom";
import {EditOutlined, PlusCircleOutlined, SyncOutlined, UserAddOutlined} from "@ant-design/icons";
import {Button, Card, Col, Input, Row} from "antd";
import {connect, useDispatch} from "react-redux";
import AppTable from "../../../components/Table";
import {useState} from "react";
import UserAvatar from "../../../components/UserAvatar";
import AddOperator from "./component/addUser";
import SetOperatorColor from "./component/SetOperatorColor";

const {Search} = Input;
function Users(props){
    const {users=[]} = props;
    const [userId,setUserId] = useState(null);
    const [activeOperator,setActiveOperator] = useState({});
    const [isShowSetOperator,setIsShowSetOperator] = useState(false);
    const [isShow,setShow] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: '',
            width: '4%',
            dataIndex: 'avatar',
            render: (_,record)=>{
                return <UserAvatar avatar={record.file} lastName={record.lastName} firstName={record.firstName}/>
            }
        },{
            title: 'User name',
            dataIndex: 'lastName',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.firstName} {record.lastName}</span>
                )
            }
        },{
            title: 'Login',
            dataIndex: 'login',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.login}</span>
                )
            }
        },{
            title: 'User phone number',
            dataIndex: 'phoneNumber',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">+{record.phoneNumber}</span>
                )
            }
        },{
            title: 'Status',
            dataIndex: 'status',
            render:(_,record)=>{
                return(
                    <p className="short-text-2">{record.status}</p>
                )
            }
        },{
            title: 'Operation',
            dataIndex: 'operation',
            width: '40%',
            render: (_, record) => {
                return(
                    <div className="text-center">
                        <Button type='primary' size='small' className="mr-2" onClick={()=>onShow(record.id)}>
                            <UserAddOutlined />  Add operator
                        </Button>
                        <Link to={`/dashboard/users/${record.id}`}  className="ant-btn ant-btn-primary mr-2 ant-btn-sm">
                            <EditOutlined/> Edit
                        </Link>
                        <Button type={record.status === 'active' ? 'danger' : 'success'} size="small" onClick={()=>changeStatus(record.id)}>
                            <SyncOutlined/> Change status
                        </Button>
                    </div>
                )
            },
        },
    ]);
    const dispatch = useDispatch();
    const outlet = useOutlet();
    const changeStatus=(id)=>{
        dispatch({
            type: CHANGE_STATUS_USER,
            payload: {
                id: parseInt(id)
            }
        })
    };
    const onSearch =(v)=>{
        dispatch({
            type: GET_USERS,
            payload: {q: v}
        })
    };
    const onShow=(id)=>{
        setUserId(id);
        setShow(true)
    };
    const onClose=()=>{
        setUserId(null);
        setShow(false);
    }
    const onChooseOperator = (item,user)=>{
        setIsShowSetOperator(true);
        setActiveOperator({
            ...item,
            userId: user.id
        });
    }
    const onCloseOperatorColor = ()=>{
        setIsShowSetOperator(false);
        setActiveOperator({});
    }
    return(
        <Card>
            {
                outlet ?
                    <Outlet/>:
                    <>
                        <Row justify='between' className="mb-3">
                            <Col span={12}>
                                <h1>Users</h1>
                            </Col>
                            <Col span={12} className="text-right">
                                <Link to='/dashboard/users/add' className="ant-btn ant-btn-primary">
                                    <PlusCircleOutlined/> Add
                                </Link>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col span={6} offset={18}>
                                <Search placeholder="Enter login!" allowClear onSearch={onSearch}/>
                            </Col>
                        </Row>
                        <AppTable
                            type={GET_USERS}
                            data={users}
                            columns={columns}
                            expandable={{
                                expandedRowRender: record => <div>
                                    {
                                        record.operators.map(item=>{
                                            return  <Button onClick={()=>onChooseOperator(item,record)} className="mr-2" type='primary' key={`operatorName${item.id}`}>
                                                        {item.titleUz}
                                                    </Button>
                                        })
                                    }
                                </div>,
                                rowExpandable: record => record.operators.length,
                            }}
                        />
                        {
                            isShow?
                                <AddOperator userId={userId} isShow={isShow} onClose={onClose}/>:''
                        }
                        {
                            isShowSetOperator ?
                                <SetOperatorColor isShow={isShowSetOperator} onClose={onCloseOperatorColor} operator={activeOperator}/> :''
                        }
                    </>
            }
        </Card>
    )
}
export default connect((state)=>{
    return {
        users: state?.users
    }
})(Users);
import {Button, Form} from "antd";
import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {GET_ONE_FIELDS, UPDATE_FIELDS} from "../../../../store/types";
import Loader from "../../../../components/Loader";

function FieldsUpdate(props) {
    const {field = {}} = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(id){
            dispatch({
                type: GET_ONE_FIELDS,
                payload: id
            });
        }
    },[id]);
    const onFinish = (value) => {
        dispatch({
            type: UPDATE_FIELDS,
            payload: value
        });
    }
    return (
        Object.keys(field).length ?
            <Form
                name='updateFields'
                onFinish={onFinish}
                initialValues={field}
                layout="vertical"
            >

                <Form.Item className="text-right mt-3">
                    <Link to='/dashboard/fields' className="ant-btn ant-btn-danger mr-3">
                        <ArrowLeftOutlined /> Back
                    </Link>
                    <Button type="primary" htmlType='submit'>
                        <PlusOutlined/> Save
                    </Button>
                </Form.Item>
            </Form>:
            <Loader type={'sm'}/>
    )
}

export default connect(
    (state)=> {
        return {field: state?.field}
    }
)(FieldsUpdate);
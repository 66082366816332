import {Col, Form, Input, Row, Select} from "antd";
import useCategory from "../../../../hooks/useCateogries";

const {Option} = Select;
function Field({value = {}, type= 'Uz'}){
    return  <>
                <Form.Item
                    label={`Sarlavha ${type}`}
                    name={`title${type}`}
                    rules={[{required: true, message: `Iltimos to'ldiring`}]}>
                    <Input placeholder="Iltimos to'ldiring"/>
                </Form.Item>
                <Form.Item
                    label={`Amal qilish muddati ${type}`}
                    name={`lifeTime${type}`}>
                    <Input placeholder="Ixtiyoriy matin"/>
                </Form.Item>
            </>
}
function SingleItems (){
    return  <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label={`Aktivlashtirish ussd kodi`}
                        name={`activateCode`}>
                        <Input placeholder="Ixtiyoriy matin"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={`O'chirish ussd kodi`}
                        name={`deActivateCode`}>
                        <Input placeholder="Ixtiyoriy matin"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={`Holatni tekshirish ussd kodi`}
                        name={`check`}>
                        <Input placeholder="Ixtiyoriy matin"/>
                    </Form.Item>
                </Col>
            </Row>
}
function Category({value}){
    const categories = useCategory('isChild');
    return  <Form.Item
                label={
                    <>
                        <span style={{color:'#ff4d4f', marginRight: '4px'}}>*</span>
                        Categories
                    </>
                }
                name='categoryId'
            >
                <Select
                    placeholder="Iltimos tanlang"
                    className="w-100"
                    defaultValue={value}
                >
                    {
                        categories.map(item=>{
                            return <Option key={`category${item.id}`} value={item.id}>{item.titleUz}</Option>
                        })
                    }
                </Select>
            </Form.Item>
}
export {Field, Category, SingleItems};
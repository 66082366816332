import {Button, Col, Form, Row, Select, Tabs} from "antd";
import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ADD_FIELDS} from "../../../../store/types";
import {Field, Category, SingleItems} from "../component";
import Editor from "../../../../components/Editor";
import {useState} from "react";

const {TabPane} = Tabs;
function FieldsAdd(props) {
    const dispatch = useDispatch();
    const [editor,setEditor] = useState({
        descriptionUz: '',
        descriptionRu: '',
        descriptionEn: '',
    });
    const onFinish = (value) => {
        dispatch({
            type: ADD_FIELDS,
            payload: value
        });
    }
    const changeEditor = (value, type)=>{
        const mEditor = editor;
        mEditor[`description${type}`] = value;
        setEditor(mEditor);
    }
    return (
        <Form
            name='fieldsAdd'
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Tab 1" key="1">
                    <Field type={'Uz'}/>
                    <Editor title="Qo'shimcha mazmuni" onChange={changeEditor} type='Uz'/>
                </TabPane>
                <TabPane tab="Tab 2" key="2">
                    <Field type={'Ru'}/>
                    <Editor title="Qo'shimcha mazmuni" onChange={changeEditor} type='Ru'/>
                </TabPane>
                <TabPane tab="Tab 3" key="3">
                    <Field type={'En'}/>
                    <Editor title="Qo'shimcha mazmuni" onChange={changeEditor} type='En'/>
                </TabPane>
            </Tabs>
            <Category/>
            <SingleItems/>
            <Form.Item className="text-right mt-3">
                <Link to='/dashboard/fields' className="ant-btn ant-btn-danger mr-3">
                    <ArrowLeftOutlined /> Back
                </Link>
                <Button type="primary" htmlType='submit'>
                    <PlusOutlined/> Save
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FieldsAdd;
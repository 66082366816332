import {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {USER_ME} from "../../store/types";
import Loader from "../Loader";

const Auth = (props)=>{
    const {children, is_user = false} = props;
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch({
            type: USER_ME
        });
    },[]);
    return is_user ? children : <Loader/>
}
const propsToState = state => {
    return {
        is_user: state?.is_user
    }
};
export default connect(propsToState, null)(Auth);
import {Layout} from "antd";
import DashboardSider from "./component/DashboardSider";
import DashboardHeader from "./component/DashboardHeader";
import DashboardContent from "./component/DashboardContent";
import Auth from "../../components/Auth";

function Dashboard(props){
    return(
        <div className="app-dashboard vh-100">
            <Auth>
                <Layout className="h-100">
                    <DashboardSider/>
                    <Layout>
                        <DashboardHeader/>
                        <DashboardContent/>
                    </Layout>
                </Layout>
            </Auth>
        </div>
    )
}
export default Dashboard;
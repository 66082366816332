import {put, takeEvery} from "redux-saga/effects";
import Req from "../api";
import {setToast} from "../actions";
import {
    ADD_CATEGORY, ADD_COLOR_TO_USER_OPERATOR,
    ADD_FIELDS,
    ADD_OPERATOR_FOR_USER,
    ADD_OPERATORS,
    ADD_USER,
    CLIENT_ADD_FIELDS, CLIENT_ADD_NEWS,
    CLIENT_REMOVE_CATEGORY,
    CLIENT_REMOVE_FIELD, CLIENT_REMOVE_NEWS,
    CLIENT_UPDATE_FIELDS, CLIENT_UPDATE_NEWS,
    GET_CATEGORY,
    GET_FIELDS, GET_MAIN_CATEGORY_LOGO_BY_USER,
    GET_ONE_CATEGORY,
    GET_ONE_FIELDS,
    GET_ONE_OPERATORS,
    GET_ONE_USER,
    GET_OPERATORS,
    GET_USER_OPERATOR,
    GET_USERS, REMOVE_BANNER,
    REMOVE_CATEGORY,
    REMOVE_FIELDS, REMOVE_MAIN_CATEGORY_LOGO_BY_USER,
    REMOVE_OPERATORS,
    REMOVE_USER,
    UPDATE_CATEGORY,
    UPDATE_FIELDS,
    UPDATE_OPERATORS,
    UPDATE_USER
} from "../types";

function parser(type){
    return type.split(' ');
}

function* Get(action){
    try{
        const [,,key] = parser(action.type);
        const {payload = new Object()} = action;
        const {limit = 10, offset=0, q = null, ...query} = payload;
        const {data} = yield Req({
            type: action.type,
            query: {
                limit: limit,
                offset: offset,
                q: q,
                ...query
            }
        });
        yield put({
            type: key,
            payload: data.content.rows
        })
        yield put({
            type: 'tableCount',
            payload: data.content.count
        });
    }
    catch(err){
        console.log(err)
        const {data} = err?.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
        if(data.status === 401){
            localStorage.removeItem('token');
            yield put({
                type: 'redirect',
                payload: `/login`
            });
        }
    }
}
function* GetNoLimit(action){
    try{
        const [,,key] = parser(action.type);
        const {data} = yield Req({
            type: action.type,
        });
        yield put({
            type: key,
            payload: data.content
        })
    }
    catch(err){
        const {data} = err.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
        if(data.status === 401){
            localStorage.removeItem('token');
            yield put({
                type: 'redirect',
                payload: '/login'
            });
        }
    }
}
function* GetOne(action){
    try{
        const id = action.payload &&  typeof action.payload === 'string'||'number' ? action.payload : null;
        const [method,url,key] = action.type.split(' ');
        const {data} = yield Req({
            type: `${method} ${url}/${id}`,
        });
        yield put({
            type: key,
            payload: data.content
        })
    }
    catch(err){
        const {data} = err.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
    }
}
function* Add(action){
    try{
        const [,url] = parser(action.type);
        const {payload = {}} = action;
        const {data} = yield Req({
            type: action.type,
            data: payload,
        });
        yield put(setToast({
            type: 'success',
            message: data?.content
        }));
        if(payload.redirectUrl){
            yield put({
                type: 'redirect',
                payload: payload.redirectUrl
            });
        }
    }
    catch (err){
        const {data} = err.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
    }
}
function* Update(action){
    try{
        const [,url] = parser(action.type);
        const {payload = {}} = action;
        const {data} = yield Req({
            type: `${action.type}/${payload.id}`,
            data: payload,
        });
        if(payload.key){
            yield put({
                type: payload.key,
                payload: {}
            })
        }
        yield put(setToast({
            type: 'success',
            message: data?.content
        }));
        if(payload.redirectUrl) {
            yield put({
                type: 'redirect',
                payload: payload.redirectUrl
            });
        }
    }
    catch (err){
        const {data} = err.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
    }
}
function* Remove(action){
    try{
        yield Req({
            type: `${action.type}/${action.payload}`,
        });
        yield put({
            type: 'reload',
            payload: Math.random()
        });
    }
    catch (err) {
        const data = err.response ? err.response.data : `Hatolik qaytadan urinib ko'ring!`;
        yield put(setToast({
            type: 'error',
            message: typeof data === 'string' ?data : data.content
        }));
    }
}

function* PostQuery(action){
    try{
        yield put({
            type: 'loading',
            payload: true
        });
        const {payload = {}, type} = action;
        const {data} = yield Req({
            type: type,
            data: payload.data,
            query: payload.query ? payload.query : {}
        });
        yield put(setToast({
            type: 'success',
            message: data.content
        }));
        yield put({
            type: 'reload',
            payload: Math.random()
        });
        yield put({
            type: 'loading',
            payload: false
        })
    }catch (err){
        const data = err.response ? err.response.data : `Hatolik qaytadan urinib ko'ring!`;
        yield put(setToast({
            type: 'error',
            message: typeof data === 'string' ?data : data.content
        }));
        yield put({
            type: 'loading',
            payload: false
        })
    }
}
function* GetQuery(action){
    try {
        const {type,payload = {}} = action;
        const {data} = yield Req({
            type: type,
            query: payload.query ? payload.query : {}
        });
        yield put({
            type: 'user_operators',
            payload: data.content
        })
    }catch (err){
        const data = err.response ? err.response.data : `Hatolik qaytadan urinib ko'ring!`;
        yield put(setToast({
            type: 'error',
            message: typeof data === 'string' ?data : data.content
        }));
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_USERS, Get);
    yield takeEvery(GET_ONE_USER, GetOne);
    yield takeEvery(REMOVE_USER, Remove);
    yield takeEvery(ADD_USER, Add);
    yield takeEvery(ADD_COLOR_TO_USER_OPERATOR, Add);
    yield takeEvery(ADD_OPERATOR_FOR_USER, PostQuery);
    yield takeEvery(GET_USER_OPERATOR, GetQuery);
    yield takeEvery(UPDATE_USER, Update);

    yield takeEvery(ADD_CATEGORY, Add);
    yield takeEvery(GET_CATEGORY, Get);
    yield takeEvery(GET_ONE_CATEGORY, GetOne);
    yield takeEvery(UPDATE_CATEGORY, Update);
    yield takeEvery(REMOVE_CATEGORY, Remove);

    yield takeEvery(ADD_OPERATORS, Add);
    yield takeEvery(GET_OPERATORS, Get);
    yield takeEvery(GET_ONE_OPERATORS, GetOne);
    yield takeEvery(UPDATE_OPERATORS, Update);
    yield takeEvery(REMOVE_OPERATORS, Remove);

    yield takeEvery(ADD_FIELDS, Add);
    yield takeEvery(GET_FIELDS, Get);
    yield takeEvery(GET_ONE_FIELDS, GetOne);
    yield takeEvery(UPDATE_FIELDS, Update);
    yield takeEvery(REMOVE_FIELDS, Remove);

    yield takeEvery(CLIENT_ADD_FIELDS, Add);
    yield takeEvery(CLIENT_UPDATE_FIELDS, Update);
    yield takeEvery(CLIENT_REMOVE_FIELD, Remove);

    yield takeEvery(CLIENT_REMOVE_CATEGORY, Remove);

    yield takeEvery(CLIENT_ADD_NEWS, Add);
    yield takeEvery(CLIENT_UPDATE_NEWS, Update);
    yield takeEvery(CLIENT_REMOVE_NEWS, Remove);

    yield takeEvery(GET_MAIN_CATEGORY_LOGO_BY_USER, Get);
    yield takeEvery(REMOVE_MAIN_CATEGORY_LOGO_BY_USER, Remove);

    yield takeEvery(REMOVE_BANNER, Remove);
}
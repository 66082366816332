import Style from "styled-components";
import {LoadingOutlined} from "@ant-design/icons";

const LoaderWrapper = Style.div`
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 32px;
    position: fixed;
    left:0;
    top:0;
    right: 0;
    bottom: 0;
`

const LoaderSmWrapper = Style.div`
    width: 100%;
    padding: 3rem;
    text-align: center;
`

function LoaderLg(props){
    return  <LoaderWrapper>
                <LoadingOutlined/>
            </LoaderWrapper>
}

function LoaderSm(props){
    return  <LoaderSmWrapper>
                <LoadingOutlined/>
            </LoaderSmWrapper>
}

function Loader(props){
    const {type = 'lg'} = props;
    return type === 'lg' ?
            <LoaderLg/> :
            <LoaderSm/>
}

export default Loader;
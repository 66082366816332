import {Outlet, useOutlet} from "react-router-dom";
import {Layout} from "antd";

const {Content} = Layout;
function DashboardContent(props){
    const outlet = useOutlet();
    return(
        <Content>
            {
                outlet ?  <Outlet/> : <h1>Dashboard content</h1>
            }
        </Content>
    )
}
export default DashboardContent;
import {BrowserRouter as Router, useLocation, useNavigate, useRoutes} from "react-router-dom";
import React, {useEffect} from "react";
import path from './path';
import {connect, useDispatch} from "react-redux";

function AppRoute() {
    return useRoutes(path);
}

function Redirect({to = null}) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        if (to && location.pathname !== to) {
            navigate(to);
        }
    }, [to]);
    useEffect(()=>{
        dispatch({
            type: 'redirect',
            payload: location.pathname
        })
        document.documentElement.scrollTo(0, 0);
    },[location]);
    return null;
}

function AppRouter({redirect}) {
    return  <Router>
                <AppRoute/>
                <Redirect to={redirect}/>
            </Router>;
}
const stateToProps=(state)=>{
    return{
        redirect: state?.redirect
    }
}
export default connect(stateToProps)(AppRouter);
import Logo from "../../../../components/Logo";
import {Layout, Menu} from "antd";
import {
    ApartmentOutlined,
    DeploymentUnitOutlined,
    PartitionOutlined,
    TagOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useState} from "react";
import {Link} from "react-router-dom";

const {Sider} = Layout;

function DashboardSider() {
    const [isOpen, setOpen] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const onOpenChange = (keys) => {
        const openKeys = isOpen;
        const inArray = keys.find(key => openKeys.indexOf(key) === -1);
        if (inArray) {
            setOpen([inArray]);
        } else {
            setOpen(keys);
        }
    }
    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div>
                <Logo/>
            </div>
            <Menu
                mode="inline"
                defaultOpenKeys={isOpen}
                openKeys={isOpen}
                onOpenChange={onOpenChange}
                style={{height: '100%', borderRight: 0}}
            >
                <Menu.Item icon={<UserOutlined/>} key="menu1">
                    <Link to="/dashboard/users">
                        Users
                    </Link>
                </Menu.Item>
                <Menu.Item icon={<DeploymentUnitOutlined/>} key="menu2">
                    <Link to="/dashboard/categories">
                        Categories
                    </Link>
                </Menu.Item>
                <Menu.Item icon={<ApartmentOutlined/>} key="menu3">
                    <Link to='/dashboard/operators'>
                        Operators
                    </Link>
                </Menu.Item>
                <Menu.Item icon={<TagOutlined />} key="menu4">
                    <Link to='/dashboard/category-user-logo'>
                        User category
                    </Link>
                </Menu.Item>
                <Menu.Item  key="menu5" icon={<PartitionOutlined/>} >
                    <Link to='/dashboard/fields'>
                        Fields
                    </Link>
                </Menu.Item>
            </Menu>
        </Sider>
    )
}

export default DashboardSider;
import {Col, Layout, Row} from "antd";
import User from "../../../../components/User";
import {connect} from "react-redux";

const {Header} = Layout;
function DashboardHeader({user = {}}){

    return(
        <Header>
            <Row justify='end'>
                <Col span={4}>
                    {
                        Object.keys(user).length ?
                            <User userName={`${user.firstName} ${user.lastName}`} img={user.file ? user.file.url : null}/>
                            :''
                    }

                </Col>
            </Row>
        </Header>
    )
}
export default connect((state)=>{
    return{
        user: state?.activeUser
    }
})(DashboardHeader);
// action functions
const setToast = (props) => {
    const {type = 'error', message = 'Error'} = props;
    return {
        type: "toast",
        payload: {
            type: type,
            message: typeof message === 'object' ? `${type.charAt(0).toUpperCase()}${type.slice(1)}` : message
        }
    }
}
export {
    setToast
}
import {useLayoutEffect, useState} from "react";
import {GET_MAIN_CATEGORY_LOGO_BY_USER, GET_USERS, REMOVE_MAIN_CATEGORY_LOGO_BY_USER} from "../../../../store/types";
import AppTable from "../../../../components/Table";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Col, Row, Select} from "antd";


const {Option} = Select;
function DataList(){
    const {categoryUserLogo = [],users = []} = useSelector(s=>s);
    const [columns] = useState([
        {
            width: '5%',
            title: 'id',
            dataIndex: 'id'
        },{
            width: '5%',
            title: 'File',
            render: (_,col)=>{
                return (
                    <div>
                        <img className="img-table" src={`${process.env.REACT_APP_SERVER_URL}/${col.file.url}`} alt=""/>
                    </div>
                )
            }
        },{
            title: 'User',
            render: (_,col)=>{
                return (
                    <div>
                        <span className="px-1">
                            {
                                col.user.firstName
                            }
                        </span>
                        <span className="px-1">
                            {
                                col.user.lastName
                            }
                        </span>
                    </div>
                )
            }
        },{
            title: 'Category',
            render: (_,col)=>{
                return (
                    <div>
                        <span className="px-1">
                            {
                                col.category.titleUz
                            }
                        </span>
                    </div>
                )
            }
        },{
            title: 'Action',
            width: '25%',
            render: (_,col)=>{
                return(
                    <div className="text-right">
                        <Button type="danger" onClick={()=>onRemove(col.id)}>
                            Remove
                        </Button>
                        <Link to={`add-update/${col.id}`} className="ant-btn ml-2">
                            Edit
                        </Link>
                    </div>
                )
            }
        }
    ]);
    const dispatch = useDispatch();
    useLayoutEffect(()=>{
        dispatch({
            type: GET_USERS,
        })
    },[]);
    const onRemove =(id)=>{
        dispatch({
            type: REMOVE_MAIN_CATEGORY_LOGO_BY_USER,
            payload: id
        })
    }
    const onFilterByUser = (id)=>{
        dispatch({
            type: GET_MAIN_CATEGORY_LOGO_BY_USER,
            payload:{
                userId: id
            }
        })
    }
    return(
        <div>
            <Row className="mb-3" gutter={16} justify="end">
                <Col span={6}>
                    <Select
                        allowClear
                        onChange={onFilterByUser} placeholder="Filter by user">
                        {
                            users.map(user=>{
                                return(
                                    <Option value={user.id} key={`filterByUser${user.id}`}>
                                        {user.firstName} {user.lastName}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col span="2">
                    <Link to={`add-update`} className="ant-btn ant-btn-primary ml-2 ant-btn-block">
                        Add
                    </Link>
                </Col>
            </Row>
            <AppTable
                type={GET_MAIN_CATEGORY_LOGO_BY_USER}
                data={categoryUserLogo}
                columns={columns}
            />
        </div>
    )
}
export default DataList;
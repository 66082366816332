const library = {
    uz: {
        //titles
        TITLE_NEWS: 'Yangiliklar',
        TITLE_PRODUCT_SORT: 'Navlari',
        TITLE_PARTNERS: 'Bining hamkorlar',
        TITLE_PARTICIPANT: 'Loyiha ishtirokchilari',
        TITLE_PLANT: "O'simliklar",
        TITLE_TOP_PRODUCTS: "Eng yaxshi 10 ta meva va sabzavotlar",
        TITLE_ABOUT_US: "Biz haqimizda",
        TITLE_CONTACTS: 'Aloqa',
        TITLE_MANI: 'Asosiy',
        TITLE_ARTICLES: 'Ilmiy maqolalar',
        TITLE_PRODUCTS: 'Eng yaxshi meva va sabzavotlar',
        TITLE_JOURNAL: '"Shirin meva" jurnali',
        TITLE_CATEGORY: 'Boshqa maxsulotlar',
        TITLE_DEMONSTRATION: "Namunaviy hududlar",
        TITLE_PHOTO_GALLERY: "Foto galireya",
        TITLE_VIDEO_GALLERY: "Video galireya",
        TITLE_NOTFOUND: 'Sahifa mavjud emas yoki o\'chirib yuborilgan',
        TITLE_FOOTER_DOWNLOAD: 'meva-sabzavotuz mobil ilovasini yuklab oling',
        TITLE_CLIMATE: "Iqlim sharoitlari",
        TITLE_SOIL: 'Tuproq',
        TITLE_SQUARE : 'Maydoni',
        TITLE_ADDRESS : 'Manzili',
        TITLE_TEMPERATURE: "Havo harorati",
        TITLE_FOOTER_NEWS: 'Nau hou (Yangiliklar)',
        TITLE_FOOTER_NEW_1: 'Yangiliklar',
        TITLE_FOOTER_NEW_2: 'Ilmiy maqolalar',
        TITLE_FOOTER_RIGHTS: 'Barcha huquqlar himoyalangan ',
        TITLE_FOOTER: 'Meva-sabzavotchilik texnologiyalari',
        TEXT_FOOTER: 'Mazkur sayt Yevropa ittifoqi tomonidan moliyalashtirilgan va Jahon banki tomonidan boshqariladigan  “O‘zbekistonda meva–sabzavotchilik texnologiyalari bo‘yicha texnik ko‘mak”loyihasining rasmiy web-portali hisoblanadi.',
        //
        //Messages
        MESSAGE_NO_DATA: "Ma'lumot mavjud emas!",
        //
        //Button
        BUTTON_MORE: 'Batafsil',
        BUTTON_WATCH_MORE: "Ko'proq ko'rsatish",
        BUTTON_MORE_LIST:"Barchasi",
        BUTTON_DOWNLOAD:"Yuklab olish",
        BUTTON_BACK_HOME: "Bosh sahifaga qaytish",
        BUTTON_BACK: "Orqaga",
        //
        month: ['yanvar','fevral','mart','aprel','may','iyun','iyul','avgust','sentabr','oktabr','noyabr','dekabr']
    },
    en:{
        //titles
        TITLE_NEWS: 'News',
        TITLE_PRODUCT_SORT: 'Varieties',
        TITLE_PARTNERS: 'Partners',
        TITLE_PARTICIPANT: 'Project participants',
        TITLE_PLANT: 'Plant',
        TITLE_TOP_PRODUCTS: "Top 10 fruits and vegetables",
        TITLE_ABOUT_US: "О нас",
        TITLE_MANI: 'Main',
        TITLE_CONTACTS: 'Contacts',
        TITLE_ARTICLES: 'Science articles',
        TITLE_PRODUCTS: 'Top fruits and vegetables',
        TITLE_JOURNAL: "Journal 'Shirin meva'",
        TITLE_CATEGORY: 'Other fruits and vegetables',
        TITLE_DEMONSTRATION: "Demonstration site",
        TITLE_PHOTO_GALLERY: "Photo gallery",
        TITLE_VIDEO_GALLERY: "Video gallery",
        TITLE_NOTFOUND: 'The page does not exist or has been deleted',
        TITLE_FOOTER_DOWNLOAD: 'Download the meva-sabzavotuz mobile app',
        TITLE_CLIMATE: 'Climate conditions',
        TITLE_SOIL: 'The soil',
        TITLE_SQUARE : 'Square',
        TITLE_ADDRESS : 'Address',
        TITLE_TEMPERATURE: 'Air temperature',
        TITLE_FOOTER_NEWS: 'Know how (News)',
        TITLE_FOOTER_NEW_1: 'News and Trends',
        TITLE_FOOTER_NEW_2: 'Scientific Articles',
        TITLE_FOOTER: 'Fruit and vegetable technologies',
        TITLE_FOOTER_RIGHTS: 'All rights reserved ',
        TEXT_FOOTER: 'This site is the official web portal of the EU-funded project "Technical Assistance in Fruit and Vegetable Technologies in Uzbekistan" funded by the World Bank.',
        //
        //Messages
        MESSAGE_NO_DATA: 'No data!',
        //
        //Button
        BUTTON_MORE: 'More details',
        BUTTON_WATCH_MORE: "Watch more",
        BUTTON_MORE_LIST:"All lists",
        BUTTON_DOWNLOAD:"Download",
        BUTTON_BACK_HOME: "Return to Home",
        BUTTON_BACK: "To back",
        //
        month: ['january','february','march','april','may','june','july','august','september','october','november','december']
    },
    ru:{
        //titles
        TITLE_NEWS: 'Новости',
        TITLE_PRODUCT_SORT: 'Разновидности',
        TITLE_PARTNERS: 'Партнеры',
        TITTLE_PARTICIPANT: "Участники проекта",
        TITLE_PLANT: 'Растение',
        TITLE_TOP_PRODUCTS: "Топ 10 фруктов и овощей",
        TITLE_ABOUT_US: "О нас",
        TITLE_MANI: 'Главны',
        TITLE_CONTACTS: 'Контакты',
        TITLE_ARTICLES: 'Научные статьи',
        TITLE_PRODUCTS: 'Топ фруктов и овощей',
        TITLE_JOURNAL: "Журнал «Shirin meva»",
        TITLE_CATEGORY: 'Другие фрукты и овощи',
        TITLE_DEMONSTRATION: "Демонстрационный участоки",
        TITLE_PHOTO_GALLERY: "Фото галерея",
        TITLE_VIDEO_GALLERY: "Видео галерея",
        TITLE_NOTFOUND: 'Страница не существует или была удалена',
        TITLE_FOOTER_DOWNLOAD: 'Скачайте мобильное приложение meva-sabzavotuz',
        TITLE_CLIMATE: 'Условия климата',
        TITLE_SOIL: 'Почва',
        TITLE_SQUARE : 'Территория',
        TITLE_ADDRESS : 'Адрес',
        TITLE_TEMPERATURE: 'Температура воздуха',
        TITLE_FOOTER_NEWS: 'Ноу хау(Новости)',
        TITLE_FOOTER_NEW_1: 'Новости и тенденции',
        TITLE_FOOTER_NEW_2: 'Научные статьи',
        TITLE_FOOTER: 'Фруктовые и овощные технологии',
        TITLE_FOOTER_RIGHTS: 'Все права защищены ',
        TEXT_FOOTER: 'Мазкур сайт Европа иттифоки томонидан молиялаштирилган ва Жахон банки томонидан бошкариладиган «Узбекистонда мева–сабзавотчилик технологиилари бо’йича техник ко‘мак»лойихасининг расмий веб-портали хисобланади.',
        //
        //Messages
        MESSAGE_NO_DATA: 'Нет данных!',
        //
        //Button
        BUTTON_MORE: 'Подробнее',
        BUTTON_WATCH_MORE: "Смотреть еще",
        BUTTON_MORE_LIST:"Весь список",
        BUTTON_DOWNLOAD:"Скачать",
        BUTTON_BACK_HOME: "Вернуться на главную",
        BUTTON_BACK: "Назад",
        //
        month: ['январь','февраль','март','апрель','май','июнь','июль','август','сентябрь','октябрь','ноябрь','декабрь']

    }
}
export default {
    ln: 'Uz',
    library: library,
    lib: library.uz,
    single: {},
    pagination:{
        current: 1,
        pageSize: 10,
        offset: 0,
        total: 0
    }
}
import {Button, Col, Form, Input, message, Row, Upload} from "antd";
import {ArrowLeftOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {ADD_OPERATORS} from "../../../../store/types";
import Field from "../component";

function OperatorAdd(props) {
    const [loading,setLoading] = useState(false);
    const [imageUrl, setImage] = useState(null);
    const dispatch = useDispatch();
    const onFinish = (value) => {
        dispatch({
            type: ADD_OPERATORS,
            payload:{
                ...value,
                fileId: imageUrl ? imageUrl.id : null,
                redirectUrl: '/dashboard/operators'
            }
        })
    }
    const beforeUpload = (file)=>{
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = info => {
        const {file} = info;
        console.log(info)
        if (file.status === 'uploading') {
            setLoading(true);
        }else{
            if(file.status === 'done'){
                setImage(file.response.content);
            }
            setLoading(false);
        }
        return null;
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <Form
            name='operatorAdd'
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Field name='titleUz' label={'Title uz'}/>
                </Col>
                <Col span={8}>
                    <Field name='titleRu' label={'Title ru'}/>
                </Col>
                <Col span={8}>
                    <Field name='titleEn' label={'Title en'}/>
                </Col>
                <Col span={8}>
                    <Field name='subTitleUz' label={'Sub title uz'}/>
                </Col>
                <Col span={8}>
                    <Field name='subTitleRu' label={'Sub title ru'}/>
                </Col>
                <Col span={8}>
                    <Field name='subTitleEn' label={'Sub title en'}/>
                </Col>
                <Col span={8}>
                    <Field name='cabinetUrl' label={'Cabinet url'}/>
                </Col>
                <Col span={8}>
                    <Field name='prefix' label={'Prefix kiriting'}/>
                </Col>
                <Col span={8}>
                    <Field name='websiteUrl' label={'Web sayt url'}/>
                </Col>
                <Col span={8}>
                    <Field name='operatorNumber' label={'Operator tel. raqami'}/>
                </Col>
                <Col span={8}>
                    <Field name='balanceCode' label={'Balans tekshirish kodi'}/>
                </Col>
                <Col span={24}>
                    <Form.Item className='mt-3' label="User avatar">
                        <Upload
                            name="file"
                            accept="image/*"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`/api/v1/file${imageUrl ? `/${imageUrl.id}`:''}`}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={`${process.env.REACT_APP_SERVER_URL}/${imageUrl.url}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item className="text-right mt-3">
                <Link to='/dashboard/operators' className="ant-btn ant-btn-danger mr-3">
                    <ArrowLeftOutlined /> Back
                </Link>
                <Button type="primary" htmlType='submit'>
                    <PlusOutlined/> Save
                </Button>
            </Form.Item>
        </Form>
    )
}

export default OperatorAdd;
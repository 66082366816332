import {Card} from "antd";
import {Outlet, useOutlet} from "react-router-dom";
import DataList from "./components/DataList";

function CategoryUserLogo(){
    const outlet = useOutlet();
    return(
        <Card>
            {
                outlet ?
                    <Outlet/> :
                    <DataList/>
            }
        </Card>
    )
}
export default CategoryUserLogo
import {Button, Card, Col, Form, Input, Row} from "antd";
import {LoginOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {AUTH, USER_ME} from "../../store/types";
function Login(props){
    const dispatch = useDispatch();
    const loginPage = useRef();
    const onFinish =(value)=>{
        dispatch({
            type: AUTH,
            payload: value
        })
    }
    useEffect(()=>{
        const token = localStorage.getItem('token');
        if(token){
            dispatch({
                type: USER_ME,
                payload: 'login'
            });
        }
    },[]);
    return (
        <Row className='app-login'>
            <Col span={15} className="app-login-image">
                <img src="/images/login.png" alt=""/>
            </Col>
            <Col span={9}>
                <Card className="p-5 vh-100 shadow">
                    <h1 className='text-center'>Login for ussd apps</h1>
                    <Row className="py-5">
                        <Col span={20} offset={2}>
                            <Form
                                ref={loginPage}
                                name="loginPage"
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item className="mb-3" label="Login" name="login" rules={[{ required: true }]}>
                                    <Input placeholder="Logini kiriting"/>
                                </Form.Item>

                                <Form.Item className="mb-3" label="Password" name="password" rules={[{ required: true }]}>
                                    <Input type='password' placeholder="Parloni kiriting"/>
                                </Form.Item>
                                <Form.Item className="text-right">
                                    <Button type="primary" htmlType="submit">
                                        <LoginOutlined/> Kirish
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

    )
}
const propsToState = (state)=>{
    return{
        isUser: state?.isUser
    }
}
export default connect(propsToState,null)(Login);
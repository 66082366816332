import {all} from 'redux-saga/effects'
import userSaga from "./userSaga";
import universalSaga from "./universalSaga";


export default function* rootSaga() {
    yield all([
        userSaga(),
        universalSaga(),
    ])
}
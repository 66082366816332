import {GET_CATEGORY, REMOVE_CATEGORY} from "../../../store/types";
import {Link, Outlet, useOutlet} from "react-router-dom";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Card, Col, Input, Row, Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import AppTable from "../../../components/Table";
import {useState} from "react";

function Categories(props){
    const {categories=[]} = props;
    const [checked,setChecked] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: 'id',
            width: '4%',
            dataIndex: 'id',
            render: (_,record)=>{
                return <p className="m-0 text-center">{record.id}</p>
            }
        },{
            title: 'Title uz',
            dataIndex: 'titleUz',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.titleUz}</span>
                )
            }
        },{
            title: 'Title ru',
            dataIndex: 'titleRu',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.titleRu}</span>
                )
            }
        },{
            title: 'Title en',
            dataIndex: 'titleEn',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.titleEn}</span>
                )
            }
        },{
            title: 'Operation',
            dataIndex: 'operation',
            width: '15%',
            render: (_, record) => {
                return(
                    <div className="text-center">
                        <Link to={`/dashboard/categories/${record.id}`}  className="ant-btn ant-btn-primary mr-2 ant-btn-sm">
                            <EditOutlined/> Edit
                        </Link>
                        <Button type='danger' size="small" onClick={()=>onRemove(record.id)}>
                            <DeleteOutlined/> Remove </Button>
                    </div>
                )
            },
        },
    ]);
    const dispatch = useDispatch();
    const outlet = useOutlet();
    const onRemove =(id)=>{
        dispatch({
            type: REMOVE_CATEGORY,
            payload: id
        })
    }
    const onChange =(value)=>{
       setChecked(value)
        dispatch({
            type: GET_CATEGORY,
            payload:{
                q: !value ? 'isParent' : 'isChild'
            }
        })
    }
    return(
        <Card>
            {
                outlet ?
                    <Outlet/>:
                    <>
                        <Row justify='between' className="mb-3">
                            <Col span={12}>
                                <h1>Users</h1>
                            </Col>
                            <Col span={12} className="text-right">
                                <Link to='/dashboard/categories/add' className="ant-btn ant-btn-primary">
                                    <PlusCircleOutlined/> Add
                                </Link>
                            </Col>
                        </Row>
                        <Row justify='end' className="my-4">
                            <Col span={4} className="text-right">
                                <strong className="pr-3">
                                    {
                                        checked ? 'Parent category': 'Child category'
                                    }
                                </strong>
                                <Switch onChange={onChange} />
                            </Col>
                        </Row>
                        <AppTable
                            type={GET_CATEGORY}
                            data={categories}
                            columns={columns}
                        />
                    </>
            }

        </Card>
    )
}
export default connect((state)=>{
    return {
        categories: state?.categories
    }
})(Categories);
// reducer for API
import {Req} from "../api";
import {call, put, takeEvery} from "redux-saga/effects";
import {setToast} from "../actions";
import {AUTH, CHANGE_STATUS_USER, USER_ME} from "../types";
import id from "../../pages/products/id";

function* checkUser(user){
    const check = user.roles.filter(v=>v.prefix === 'ADMIN');
    if(check.length){
        return yield call(redirect, '/dashboard');
    }
    return yield call(redirect, '/client');

}

function* redirect(path){
    yield put({
        type: 'redirect',
        payload: path
    });
}

function* Login(action){
    try{
        const {data} = yield Req({
            type: action.type,
            data: action.payload
        });
        localStorage.setItem('token',`Bearer ${data.content.token}`);
        yield put({
            type: "activeUser",
            payload: data.content.user
        });
        yield put(setToast({
            type: 'success',
            message: data.content
        }));
        const {user = {}} = data.content;
        if(Object.keys(user).length){
            const isAdmin = checkUser(user, 'ADMIN');
            if(isAdmin){
                return yield call(redirect, '/dashboard');
            }
            return yield call(redirect, '/client');
        }

    }catch (err){
        const content = err.response.data ? err.response.data.content : 'Adminga murjaat qilng!'
        yield put(setToast({
            type: 'error',
            message: content
        }));
    }
}
function* UserMe(action){
    try{
        const {data} = yield Req({
            type: action.type
        });
        if(data.type){
            yield put({
                type: "activeUser",
                payload: data.content
            });
            yield put({
                type: 'is_user',
                payload: true
            });
            const user = data.content;
            if(Object.keys(user).length){
                yield call(checkUser, user)
            }
            if(action.payload && action.payload === 'login'){

            }
        }
    }catch (err){
        console.log(err)
        const data = err.response ? err.response.data : `Hatolik qaytadan urunib ko'ring!`;
        yield put(setToast({
            type: 'error',
            message: typeof data === 'string' ? data : data.content
        }));
        if(data.status === 401){
            localStorage.removeItem('token');
            yield call(redirect, '/login');
        }
    }
}
function* ChangeStatus(action){
    try {
        const {data} = yield Req({
            type: `${action.type}/${action.payload.id}`,
            data: action.payload,
        });
        yield put(setToast({
            type: 'success',
            message: data?.content
        }));
        yield put({
            type: 'reload',
            payload: Math.random()
        })
    }catch (err){
        const {data} = err.response;
        yield put(setToast({
            type: 'error',
            message: data?.content
        }));
    }
}
function* LogOut(action){
    localStorage.removeItem('token');
    yield put({
        type: 'activeUser',
        payload: null
    });
    yield put({
        type: 'isUser',
        payload: false
    });
    yield call(redirect,'/login');
}

export default function* userSaga(){
    yield takeEvery(AUTH,Login);
    yield takeEvery('logOut',LogOut);
    yield takeEvery(USER_ME, UserMe);
    yield takeEvery(CHANGE_STATUS_USER, ChangeStatus);
}
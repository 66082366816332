import {Button, Card, Col, Form, Input, message, Row, Skeleton, Spin} from "antd";
import {useLayoutEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowLeftOutlined, SaveOutlined} from "@ant-design/icons";
import Req from "../../../../store/api";
import {ADD_BANNER, GET_ONE_CLIENT_BANNER, UPDATE_BANNER} from "../../../../store/types";

function ClientBannerAddUpdate(){
    const [form] = Form.useForm();
    const {operatorId,bannerId} = useParams();
    const fileInput = useRef();
    const [loading,setLoading] = useState(false)
    const [fileLoader,setFileLoader] = useState(false)
    const [file,setFile] = useState(null);
    const navigate = useNavigate();
    useLayoutEffect(()=>{
        if(bannerId){
            console.log(bannerId);
            setLoading(true)
            Req({
                type: `${GET_ONE_CLIENT_BANNER}/${bannerId}`,
                query:{
                    operatorId
                }
            })
                .then(res=>{
                    setFile(res.data.content.file)
                    form.setFieldsValue({
                        url: res.data.content.url,
                        fileId: res.data.content.fileId
                    });
                })
                .catch(err =>{

                })
                .finally(()=>{
                    setLoading(false)
                })
        }
    },[])
    const onFinish = (v)=>{
        setFileLoader(true);
        Req({
            type: bannerId ? UPDATE_BANNER : ADD_BANNER,
            data:{
                fileId: v.fileId,
                operatorId: operatorId,
                url: v.url
            }
        })
            .then(res=>{
                navigate(`/client/banner/${operatorId}`);
            })
            .catch(err=>{
                message.error(`Saqalsh bilan bog'liq muamo!`)
            })
            .finally(()=>{
                setFileLoader(false)
            })
    }
    const onUploadFile = ()=>{
        setFileLoader(true);
        const formData = new FormData();
        formData.append('file', fileInput.current.files[0]);
        Req({
            type: 'post file',
            data: formData,
        })
            .then(res=>{
                setFile(res.data.content);
                form.setFieldsValue({
                    fileId: res.data.content.id
                })
                message.success('File yuklandi. Yakunlash uchun saqlang!');
            })
            .catch(err=>{
                message.error(`File yuklash bilan bog'liq hatolik!`)
            })
            .finally(()=>{
                setFileLoader(false);
            })
    }
    const onBack = ()=>{
        if(file && file.id){
            Req({
                type: `delete file/${file.id}`
            })
            navigate(`/client/banner/${operatorId}`);
        }else{
            navigate(`/client/banner/${operatorId}`)
        }
    }
    return(
        <Card>
            {
                loading ?
                    <Skeleton active/>:
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        name="clientBannerForm"
                        form={form}
                    >
                        <Form.Item
                            label="Banner uchun tashqi link"
                            name="url"
                            rules={[
                                {
                                    type: 'url',
                                    warningOnly: true,
                                    message: 'Iltimos link uchun mos satir kiriting'
                                },
                                {
                                    type: 'string',
                                    min: 6,
                                    message: 'Min. 6 ta satir'
                                },
                            ]}
                        >
                            <Input placeholder='Test.uz'/>
                        </Form.Item>
                        <Form.Item
                            label="Banner uchun file tanlang"
                            name="fileId"
                            rules={[
                                {
                                    required: true,
                                    message: "Iltimos file tanlang!"
                                }
                            ]}
                        >
                            <Input hidden/>
                            <Button block style={{height:'auto'}}>
                                {
                                    fileLoader ?
                                        <Spin/>:
                                        <input accept='image/png, image/jpg, image/jpeg' className="w-100" disabled={fileLoader} ref={fileInput} type="file" onChange={onUploadFile}/>
                                }
                            </Button>
                        </Form.Item>
                        {
                            file ?
                                <Row className="mb-3">
                                    <Col span={24}>
                                        <img className="img-fluid" src={`${process.env.REACT_APP_SERVER_URL}/${file.url}`} alt=""/>
                                    </Col>
                                </Row>:''
                        }
                        <div className="text-right">
                            <Button
                                className="mr-2"
                                onClick={onBack}
                                type="danger"
                            >
                                <ArrowLeftOutlined /> Bekor qilish
                            </Button>
                            <Button disabled={fileLoader} htmlType="submit" type="primary">
                                <SaveOutlined/> Saqlash
                            </Button>
                        </div>
                    </Form>
            }
        </Card>
    )
}
export default ClientBannerAddUpdate
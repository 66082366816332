import Style from "styled-components";
import {SmileOutlined} from "@ant-design/icons";

const Wrapper = Style.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #f2f2f2;
    background-color: #f1f1f1;
    position: relative;
`
const Avatar = Style.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const NoAvatar = Style.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 18px;
    color: #909090;
   
`
function ClientAvatar({user}){
    return  <Wrapper>
                {
                    user.fiel ?
                        <Avatar src={user.file}/>:
                        <NoAvatar>
                            <SmileOutlined/>
                        </NoAvatar>
                }
            </Wrapper>
}
export default ClientAvatar;
import {Button, Card, Form, Skeleton} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Req from "../../../../../store/api";
import {CLIENT_GET_ONE_FIELD, CLIENT_UPDATE_FIELDS} from "../../../../../store/types";
import {connect, useDispatch} from "react-redux";
import FormField from "../component/FormField";
import {ArrowLeftOutlined, SaveOutlined} from "@ant-design/icons";

function UpdateField({editorUz,editorRu,editorEn}){
    const {childCategoryId,operatorId, categoryId, candidateId} = useParams();
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState({});
    useEffect(()=>{
        if(candidateId){
            setLoader(true)
            Req({
                type: `${CLIENT_GET_ONE_FIELD}/${candidateId}`,
            })
                .then(res=>{
                    const {content} = res.data;
                    setLoader(false);
                    dispatch({
                        type: `editorUz`,
                        payload: content?.content.descriptionUz
                    });
                    dispatch({
                        type: `editorRu`,
                        payload: content?.content.descriptionRu
                    });
                    dispatch({
                        type: `editorEn`,
                        payload: content?.content.descriptionEn
                    });
                    setData({
                        ...content,
                        ...content.content
                    });
                })
                .catch(err=>{
                    setLoader(false)
                    dispatch({
                        type: 'toast',
                        payload: {
                            type: 'error',
                            message: `Hatolik qayta urinib ko'ring!`
                        }
                    })
                    navigate(`/client/category/${operatorId}/${categoryId}/${childCategoryId}`)
                });
        }
    },[candidateId]);
    const onFinish = (v)=>{
        dispatch({
            type: CLIENT_UPDATE_FIELDS,
            payload: {
                ...data,
                ...v,
                order: parseInt(v.order),
                descriptionUz:editorUz,
                descriptionRu:editorRu,
                descriptionEn:editorEn,
                categoryId: childCategoryId,
                id: candidateId,
                redirectUrl: `/client/category/${operatorId}/${categoryId}/${childCategoryId}`
            }
        });

    }
    return  <Card className="my-4">
                {
                    Object.keys(data).length ?
                        <Form
                            name='field-form'
                            layout='vertical'
                            onFinish={onFinish}
                            initialValues={data}
                        >
                            <FormField/>
                            <Form.Item className="text-right">
                                <Link to={`/client/category/${operatorId}/${categoryId}/${childCategoryId}`}
                                      className="ant-btn ant-btn-danger mr-2"
                                >
                                    <ArrowLeftOutlined /> Bekor qilish
                                </Link>
                                <Button type='primary' htmlType='submit'>
                                    <SaveOutlined /> Saqlash
                                </Button>
                            </Form.Item>
                        </Form>:
                        <Skeleton active/>
                }
            </Card>
}
export default connect(s=>{
    return{
        editorUz: s?.editorUz,
        editorRu: s?.editorRu,
        editorEn: s?.editorEn,
    }
})(UpdateField);
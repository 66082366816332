import Products from "../pages/products";
import ProductsAll from "../pages/products/all";
import ProductsSingle from "../pages/products/id";
import React from "react";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Users from "../pages/dashboard/users";
import DashboardNotFound from "../pages/dashboard/not-found";
import UserAdd from "../pages/dashboard/users/add";
import UpdateUser from "../pages/dashboard/users/update";
import Categories from "../pages/dashboard/category";
import CategoryAdd from "../pages/dashboard/category/add";
import CategoryUpdate from "../pages/dashboard/category/update";
import Operators from "../pages/dashboard/operators";
import OperatorAdd from "../pages/dashboard/operators/add";
import OperatorUpdate from "../pages/dashboard/operators/update";
import Fields from "../pages/dashboard/fields";
import FieldsAdd from "../pages/dashboard/fields/add";
import FieldsUpdate from "../pages/dashboard/fields/update";
import Client from "../pages/client";
import ClientCategory from "../pages/client/category";
import Field from "../pages/client/category/field";
import AddField from "../pages/client/category/field/add";
import UpdateField from "../pages/client/category/field/update";
import DashboardUserCategory from "../pages/dashboard/users/category/[userId]";
import CategoryUserLogo from "../pages/dashboard/category-user-logo";
import CategoryUserLogoAddUpdate from "../pages/dashboard/category-user-logo/add-update";
import ClientBanner from "../pages/client/banner";
import ClientBannerAddUpdate from "../pages/client/banner/add-update";

export default [
    {
        path: '/',
        index: true,
        element: <Login/>,
    }, {
        path: '/login',
        index: false,
        element: <Login/>,
    }, {
        path: 'products',
        index: false,
        element: <Products/>,
    }, {
        path: `products/all`,
        index: false,
        element: <ProductsAll/>
    }, {
        path: `products/:id`,
        element: <ProductsSingle/>,
        index: false
    },
    {
        path: `dashboard`,
        element: <Dashboard/>,
        index: false,
        children: [
            {
                path: `users`,
                element: <Users/>,
                index: false,
                children: [
                    {
                        path: 'operator/:operatorId/:userId',
                        element: <DashboardUserCategory/>
                    },
                    {
                        path: 'add',
                        element: <UserAdd/>
                    },
                    {
                        path: ':id',
                        element: <UpdateUser/>
                    }
                ]
            },
            {
                path: 'category-user-logo',
                element: <CategoryUserLogo/>,
                children: [
                    {
                        path: 'add-update',
                        element: <CategoryUserLogoAddUpdate/>
                    },
                    {
                        path: 'add-update/:categoryUserLogoId',
                        element: <CategoryUserLogoAddUpdate/>
                    }
                ]
            },
            {
                path: `categories`,
                element: <Categories/>,
                index: false,
                children: [
                    {
                        path: 'add',
                        element: <CategoryAdd/>
                    },
                    {
                        path: ':id',
                        element: <CategoryUpdate/>
                    }
                ]
            },
            {
                path: `operators`,
                element: <Operators/>,
                children: [
                    {
                        path: 'add',
                        element: <OperatorAdd/>
                    },
                    {
                        path: ':id',
                        element: <OperatorUpdate/>
                    }
                ]
            },
            {
                path: `fields`,
                element: <Fields/>,
                children: [
                    {
                        path: 'add',
                        element: <FieldsAdd/>
                    },
                    {
                        path: ':id',
                        element: <FieldsUpdate/>
                    }
                ]
            },
            {
                path: `*`,
                element: <DashboardNotFound/>,
            },
        ]
    },
    {
        path: 'client',
        element: <Client/>,
        children: [
            {
                path: 'category/:operatorId/:categoryId',
                element: <ClientCategory/>,
                children: [{
                    path: ':childCategoryId',
                    element: <Field/>,
                    children: [{
                        path: 'add',
                        element: <AddField/>
                    }, {
                        path: ':candidateId',
                        element: <UpdateField/>
                    }]
                }]
            },
            {
                path: 'banner/:operatorId',
                element: <ClientBanner/>,
                children: [
                    {
                        path: 'add-update',
                        element: <ClientBannerAddUpdate/>
                    },
                    {
                        path: 'add-update/:bannerId',
                        element: <ClientBannerAddUpdate/>
                    }
                ]
            },
            {
                path: '*',
                element: <DashboardNotFound/>
            }
        ]
    }
]
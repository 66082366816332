import Style from "styled-components";
import {useState} from "react";

const AvatarWrapper = Style.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items:center;
    justify-content:center;
    background-color: ${props => props.color};
    cursor: default;
    overflow: hidden;
`
const Avatar = Style.img`
    width: 100%;
    height:100%;
    object-fit: cover;
`
const AvatarTitle = Style.h1`
    font-size: 18px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
`
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function UserAvatar({avatar,lastName,firstName}){
    const [color,setColor] = useState(getRandomColor());
    return  <AvatarWrapper color={`${color}`}>
                {
                    avatar ?
                        <Avatar src={`${process.env.REACT_APP_SERVER_URL}/${avatar.url}`}/>:
                        <AvatarTitle>
                            {firstName[0]}{lastName[0]}
                        </AvatarTitle>
                }
            </AvatarWrapper>
}

export default UserAvatar;
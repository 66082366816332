import useOperators from "../../../../hooks/useOperators";
import {Button, Menu} from "antd";
import {TagsOutlined} from "@ant-design/icons";
import useCategory from "../../../../hooks/useCateogries";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const {SubMenu} = Menu;
function AppMenu(props){
    const operators = useOperators();
    const category = useCategory('isParent');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onToCategory=(op,cat)=>{
        dispatch({
            type: 'activeCategory',
            payload: cat
        })
        navigate(`/client/category/${op.id}/${cat.id}`);
    }
    return  <Menu
                theme='dark'
                mode="inline"
            >
                {
                    operators.map(op=>{
                            return <SubMenu icon={<TagsOutlined/>} key={`operator${op.id}`} title={op.titleUz}>
                                        {
                                            category.map(cat=>{
                                                return  (
                                                    <Menu.Item key={`category${cat.id}${op.id}`}>
                                                        <Button className="text-light" type='link' onClick={()=>onToCategory(op,cat)}>
                                                            {cat.titleUz}
                                                        </Button>
                                                    </Menu.Item>
                                                )
                                            })
                                        }
                                        <Menu.Item key={`category${operators.length}`}>
                                            <Link to={`/client/banner/${op.id}`} className="ant-btn ant-btn-link text-light">
                                                Banner
                                            </Link>
                                        </Menu.Item>
                                    </SubMenu>
                    })
                }
            </Menu>
}
export default AppMenu;
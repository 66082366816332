import {Col, Row} from "antd";


function ClientComponent(props) {
    return <Row gutter={16} className="py-3">
                <Col span={24}>
                    Client Cabinet
                </Col>
            </Row>
}

export default ClientComponent;
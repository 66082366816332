import {Link, useNavigate, useParams} from "react-router-dom";
import {useLayoutEffect} from "react";
import Req from "../../../../store/api";
import {
    CREATE_MAIN_CATEGORY_LOGO_BY_USER,
    GET_CATEGORY,
    GET_ONE_CATEGORY_LOGO_BY_USER,
    GET_OPERATORS,
    GET_USERS,
    UPDATE_MAIN_CATEGORY_LOGO_BY_USER
} from "../../../../store/types";
import {Button, Col, Form, message, Row, Select, Upload} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {UploadOutlined} from "@ant-design/icons";


const {Option} = Select;
function CategoryUserLogoAddUpdate(){
    const {categories = [],users=[],operators = []} = useSelector(s=>s);
    const {categoryUserLogoId} = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const router = useNavigate();
    useLayoutEffect(()=>{
        if(categoryUserLogoId){
            Req({
                type: `${GET_ONE_CATEGORY_LOGO_BY_USER}${categoryUserLogoId}`,
            })
                .then(res=>{
                    form.setFieldsValue(res.data.content)
                })
        }
        dispatch({
            type:GET_OPERATORS,
        })
        dispatch({
            type: GET_CATEGORY,
            payload:{
                q: 'isParent'
            }
        });
        dispatch({
            type: GET_USERS,
        })
    },[categoryUserLogoId]);
    const props = {
        accept: 'image/png, image/jpeg, image/jpg',
        action: '/api/v1/file',
        listType: 'file',
        previewFile(file) {
            console.log('Your upload file:', file);
            // Your process logic. Here we just mock to the same file
            return fetch('/api/v1/file', {
                method: 'POST',
                body: file,
            })
                .then((res) => res.json())
                .then(({ thumbnail }) => thumbnail);
        },
    };
    const onFinish = (v)=>{
        Req({
            type: categoryUserLogoId ? `${UPDATE_MAIN_CATEGORY_LOGO_BY_USER}${categoryUserLogoId}` : CREATE_MAIN_CATEGORY_LOGO_BY_USER,
            data: {
                fileId: v.fileId.file.response.content.id,
                userId: v.userId,
                categoryId: v.categoryId,
                operatorId: v.operatorId
            }
        })
            .then(res=>{
                message.success('Saqlandi')
               router('/dashboard/category-user-logo');
            })
            .catch(err=>{
                console.log(err);
                message.error(`Qayta urinib ko'ring!`);
            })
    }
    return(
        <div>
            <Form
                layout="vertical"
                name={'categoryLogoForm'}
                onFinish={onFinish}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Category"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            name="categoryId"
                        >
                            <Select placeholder="Category">
                                {
                                    categories.map(c=>{
                                        return(
                                            <Option value={c.id} key={`category${c.id}`}>
                                                {c.titleUz}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Users"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            name="userId"
                        >
                            <Select placeholder="Users">
                                {
                                    users.map(u=>{
                                        return(
                                            <Option value={u.id} key={`users${u.id}`}>
                                               <strong>id: {u.id} </strong> | {u.firstName} {u.lastName}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Operator"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            name="operatorId"
                        >
                            <Select placeholder="Operators">
                                {
                                    operators.map(u=>{
                                        return(
                                            <Option value={u.id} key={`operators${u.id}`}>
                                                {u.titleUz}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="File"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            name="fileId"
                        >
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                            {/*<Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>*/}
                            {/*    <Upload.Dragger name="file" action={`/api/v1/file`}>*/}
                            {/*        <p className="ant-upload-drag-icon">*/}
                            {/*            <InboxOutlined />*/}
                            {/*        </p>*/}
                            {/*        <p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                            {/*        <p className="ant-upload-hint">Support for a single or bulk upload.</p>*/}
                            {/*    </Upload.Dragger>*/}
                            {/*</Form.Item>*/}
                        </Form.Item>
                    </Col>
                </Row>
                <div className="text-right">
                    <Link to={"/dashboard/category-user-logo"} className="ant-btn ant-btn-link">
                        Back
                    </Link>
                    <Button type="primary" htmlType='submit' className="ml-3">
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    )
}
export default CategoryUserLogoAddUpdate
import AppRouter from "./routers";
import {Provider} from "react-redux";
import state from './store'
import Toast from "./components/Toast";
function App() {
  return (
      <Provider store={state}>
        <Toast/>
        <AppRouter/>
      </Provider>
  )
}

export default App;

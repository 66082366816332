import {Button, Card, Col, message, Row} from "antd";
import {GET_CLIENT_BANNER, REMOVE_BANNER} from "../../../../store/types";
import AppTable from "../../../../components/Table";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useLayoutEffect, useState} from "react";
import Req from "../../../../store/api";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";

function ListData(){
    const {reload = null} = useSelector(s=>s);
    const {operatorId = null} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader,setLoader] = useState(false);
    const [data,setData] =  useState([]);
    const [columns] = useState([
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
        },{
            width: '5%',
            title: 'File',
            render: (_,col)=>{
                return(
                    col.file ?
                    <img className="img-table" src={`${process.env.REACT_APP_SERVER_URL}/${col.file.url}`} alt=""/> : ''
                )
            }
        },{
            title: 'Operator',
            render: (_,col)=>{
                return(
                    col.operator && <span>{col.operator.titleUz}</span>
                )
            }
        },{
            title: 'Amallar',
            render:(_,col)=>{
                return(
                    <div className="text-right">
                        <Button onClick={()=>onRemove(col.id)} type="danger" className="mr-2">
                            <DeleteOutlined /> O'chirish
                        </Button>
                        <Link to={`/client/banner/${operatorId}/add-update/${col.id}`} className='ant-btn ant-btn-primary'>
                            <EditOutlined /> O'zgartirish
                        </Link>
                    </div>
                )
            }
        }
    ]);
    useEffect(()=>{
        if(operatorId){
            setLoader(true);
            Req({
                type:GET_CLIENT_BANNER,
                query: {
                    operatorId
                }
            })
                .then(res=>{
                    setData(res.data.content);
                })
                .catch(err=>{
                    console.log(err);
                    message.error('Hatolik qayta urinib ko`ring!')
                })
                .finally(()=>{
                    setLoader(true)
                })
        }else{
            navigate('/client');
        }
    },[operatorId,reload]);
    const onRemove =(id)=>{
        dispatch({
            type: REMOVE_BANNER,
            payload: id
        });
    };
    return(
        <Card>
           <Row gutter={16} justify={'end'} className='mb-3'>
               <Col>
                   <Link to='add-update' className='ant-btn ant-btn-primary'>
                       <PlusOutlined /> Yangi qo'shish
                   </Link>
               </Col>
           </Row>
            <AppTable
                loader={loader}
                columns={columns}
                data={data}
            />
        </Card>
    )
}
export default ListData
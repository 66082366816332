import {useSelector} from "react-redux";
import ClientAvatar from "../clientAvatar";
import Style from "styled-components";
import {Button, Col, Dropdown, Menu, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {LogoutOutlined} from "@ant-design/icons";

const UserName = Style.p`
    margin: 0;
    color: #303030;
    padding: 0 1rem;
    font-weight: bold;
`

function DropDownMenu(props){
    const navigate = useNavigate();
    const onLogOut = ()=>{
        localStorage.removeItem('token');
        navigate('/login');
    }
    return  <Menu>
                <Menu.Item>
                    <Button onClick={onLogOut} block>
                        <LogoutOutlined /> Chiqish
                    </Button>
                </Menu.Item>
            </Menu>
}

function ClientUser(props){
    const user = useSelector(s=>s.activeUser);
    return  <Dropdown overlay={<DropDownMenu/>}>
                <Row align='middle' justify="end">
                    <Col>
                        <ClientAvatar user={user}/>
                    </Col>
                    <Col>
                        <UserName>{user.firstName} {user.lastName}</UserName>
                    </Col>
                </Row>
            </Dropdown>
}
export default ClientUser;
import {GET_OPERATORS, REMOVE_OPERATORS} from "../../../store/types";
import {Link, Outlet, useOutlet} from "react-router-dom";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Card, Col, Row} from "antd";
import {connect, useDispatch} from "react-redux";
import AppTable from "../../../components/Table";
import {useState} from "react";

function Operators(props){
    const {operators=[]} = props;
    const [columns, setColumns] = useState([
        {
            title: 'id',
            width: '4%',
            dataIndex: 'id',
            render: (_,record)=>{
                return record.id
            }
        },{
            title: 'Operator',
            dataIndex: 'title',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.titleUz}/{record.titleRu}/{record.titleEn}</span>
                )
            }
        },{
            title: 'Sub title',
            dataIndex: 'subtitle',
            render:(_,record)=>{
                return(
                    <span className="short-text-2">{record.subTitleUz}</span>
                )
            }
        },{
            title: 'Operation',
            dataIndex: 'operation',
            width: '25%',
            render: (_, record) => {
                return(
                    <div className="text-center">
                        <Link to={`/dashboard/operators/${record.id}`}  className="ant-btn ant-btn-primary mr-2 ant-btn-sm">
                            <EditOutlined/> Edit
                        </Link>
                        <Button type='danger' size="small" onClick={()=>onRemove(record.id)}>
                            <DeleteOutlined/> Remove
                        </Button>
                    </div>
                )
            },
        },
    ]);
    const dispatch = useDispatch();
    const onRemove =(id)=>{
        dispatch({
            type: REMOVE_OPERATORS,
            payload: id
        })
    }
    const outlet = useOutlet();
    return(
        <Card>
            {
                outlet ?
                    <Outlet/>:
                    <>
                        <Row justify='between' className="mb-3">
                            <Col span={12}>
                                <h1>Operators</h1>
                            </Col>
                            <Col span={12} className="text-right">
                                <Link to='/dashboard/operators/add' className="ant-btn ant-btn-primary">
                                    <PlusCircleOutlined/> Add
                                </Link>
                            </Col>
                        </Row>
                        <AppTable
                            type={GET_OPERATORS}
                            data={operators}
                            columns={columns}
                        />
                    </>
            }
        </Card>
    )
}
export default connect((state)=>{
    return {
        operators: state?.operators
    }
})(Operators);
import Auth from "../../components/Auth";
import {Outlet, useOutlet} from "react-router-dom";
import ClientComponent from "./compoentns/clientComponent";
import {Col, Layout, Row} from "antd";
import AppMenu from "./compoentns/Menu";
import ClientUser from "./compoentns/clientUser";
import AppHeader from "./compoentns/AppHeader";
import Logo from "../../components/Logo";
import Style from "styled-components";

const H1 = Style.h1`
    color: #fff;
    font-size: 20px;
    margin: 0;
`

const {Sider, Content, Header} = Layout
function Client(props){
    const outlet = useOutlet();
    return  <Auth>
                <Layout className='client'>
                    <Sider theme='dark'>
                        <Row justify='center' gutter={8} align='middle'>
                            <Col>
                                <Logo/>
                            </Col>
                            <Col>
                                <H1>
                                    My USSD
                                </H1>
                            </Col>
                        </Row>
                        <AppMenu/>
                    </Sider>
                    <Content>
                        <Header>
                           <AppHeader/>
                        </Header>
                        <div className="p-4">
                            {
                                outlet ?
                                    <Outlet/>:
                                    <ClientComponent/>
                            }
                        </div>
                    </Content>
                </Layout>
            </Auth>
}
export default Client;
import {Modal, Select} from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ADD_OPERATOR_FOR_USER, GET_OPERATORS, GET_USER_OPERATOR} from "../../../../../store/types";
import Req from "../../../../../store/api";
import {useDispatch, useSelector} from "react-redux";


const {Option} = Select;

function AddOperator({isShow,onClose,userId,}){
    const loading = useSelector(s=>s.loading ? s.loading : false);
    const reload = useSelector(s=>s.reload ? s.reload : null);
    const dispatch = useDispatch();
    const [operators,setOperators] = useState([]);
    const [value,setValue] = useState([]);
    useEffect(()=>{
        Req({
            type: GET_OPERATORS
        })
            .then(res=>{
                setOperators(res.data.content.rows);
            })
    },[]);
    useEffect(()=>{
        Req({
            type: GET_USER_OPERATOR,
            query: {
                userId: userId
            }
        })
            .then(res=>{
                if(res.data.content.length){
                    const ids = res.data.content.map(v=> v.id);
                    setValue(ids);
                }else{
                    setValue(res.data.content);
                }
            })
    },[reload]);
    const handleSelect = (v)=>{
        dispatch({
            type: ADD_OPERATOR_FOR_USER,
            payload:{
                data: {
                    userId: userId,
                    operatorId: v
                },
                query:{
                  type: 'add'
                }
            }
        })
    }
    const handleDeselect = (v)=>{
        dispatch({
            type: ADD_OPERATOR_FOR_USER,
            payload:{
                data: {
                    userId: userId,
                    operatorId: v
                },
                query:{
                    type: 'remove'
                }
            }
        })
    }
    const onOk=()=>{
        onClose();
    }
    return <Modal
                title="Foydalanuvchi tanlang!"
                visible={isShow}
                onOk={onOk}
                onCancel={onClose}
            >
                <p>Users</p>
                <Select
                    mode='multiple'
                    value={value}
                    loading={loading}
                    disabled={loading}
                    placeholder='Please select user!'
                    filterOption={(input, option) =>{
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    style={{width: '100%'}}
                >
                    {
                        operators.map(u=>{
                            return  <Option key={`operators${u.id}`} value={u.id}>
                                        {u.titleUz}
                                    </Option>
                        })
                    }
                </Select>
            </Modal>
}
export default AddOperator;
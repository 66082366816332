import {Link, Outlet, useNavigate, useOutlet, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    CLIENT_GET_CATEGORY_BY_ID,
    CLIENT_GET_CATEGORY_BY_USER,
    CLIENT_GET_OPERATOR,
    CLIENT_REMOVE_CATEGORY
} from "../../../store/types";
import Req from "../../../store/api";
import {Button, Card, Col, Dropdown, Menu, Row} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {connect, useDispatch} from "react-redux";
import AddCategory from "../compoentns/AddCategory";

function ClientCategory({reload = null, visible = false, activeCategory = {}}){
    const {categoryId,operatorId,childCategoryId = null} = useParams();
    const outlet = useOutlet();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [activeOperator, setActiveOperator] = useState({});
    useEffect(()=>{
        if(operatorId){
            Req({
                type: `${CLIENT_GET_OPERATOR}${operatorId}`,
            })
                .then(res=>{
                    setActiveOperator(res.data.content);
                })
        }
    },[operatorId])
    useEffect(()=>{
        if(categoryId && operatorId){
            Req({
                type: CLIENT_GET_CATEGORY_BY_USER,
                query: {
                    operatorId: operatorId,
                    categoryId: categoryId
                }
            }).then(res=>{
                setCategories(res.data.content);
            })
        }
    },[categoryId,operatorId,reload]);
    const onOpen = ()=>{
        dispatch({
            type: 'visible',
            payload: true
        })
    }
    return  <>
                <Card>
                    <Row>
                        <Col span={24}>
                            <h1 className="mb-0">{activeOperator.titleUz} operatori</h1>
                            <p className="mt-0">
                                {activeCategory.titleUz}
                            </p>
                            {
                                categories.length ?
                                    categories.map(item=>{
                                        return <ItemCategory key={`category${item.id}`} operatorId={operatorId} categoryId={categoryId} childCategoryId={childCategoryId} item={item}/>
                                    }): ''
                            }
                            <Button onClick={onOpen} size='sm' type='primary'>
                                <PlusOutlined /> Qo'shish
                            </Button>
                        </Col>
                    </Row>
                    {
                        visible ?
                            <AddCategory/> : ''
                    }
                </Card>
                {
                    outlet ?
                        <Outlet/> : ''
                }
            </>
}

const DropDownMenu = ({id})=>{
    const dispatch = useDispatch()
    const onRemove= ()=>{
        dispatch({
            type: CLIENT_REMOVE_CATEGORY,
            payload: id,
        });
    }
    const onChangeCategory=(id)=>{
        Req({
            type: `${CLIENT_GET_CATEGORY_BY_ID}/${id}`,
        })
            .then(res=>{
                dispatch({
                    type: 'dividerCategory',
                    payload: res.data.content
                });
                dispatch({
                    type: 'visible',
                    payload: true
                });
            })
    }
    return <Menu>
                <Menu.Item>
                    <Button type='primary' onClick={()=>onChangeCategory(id)} className="mr-2">
                        <EditOutlined />
                    </Button>
                    <Button type='danger' onClick={()=>onRemove(id)}>
                        <DeleteOutlined />
                    </Button>
                </Menu.Item>
            </Menu>
}
function ItemCategory({operatorId,categoryId,childCategoryId,item}){

    return  <Dropdown.Button overlay={<DropDownMenu id={item.id}/>} className={`mr-2 ${childCategoryId == item.id ? 'active-category' : ""}`}>
                <Link to={`/client/category/${operatorId}/${categoryId}/${item.id}`}
                      style={{width: '100%'}}
                      key={`cat${item.id}`}>
                    {item.titleUz}
                </Link>
            </Dropdown.Button>
}
export default connect(s=>{
    return{
        reload: s?.reload,
        visible: s?.visible,
        activeCategory: s?.activeCategory
    }
})(ClientCategory);
// READ ME ! =========================
// Add spaces at the end of get all methods

//Client types
export const LOADING = 'loader';
//
export const UPLOAD_FILE = 'post file';
export const REMOVE_FILE = 'delete file';

// Date
//User types
export const AUTH = 'post users/auth';
export const USER_ME = 'post users/me';
export const GET_USERS = 'get users users';
export const GET_ONE_USER = 'get users user';
export const ADD_USER = 'post users';
export const UPDATE_USER = 'put users';
export const REMOVE_USER = 'delete users';
export const CHANGE_STATUS_USER = 'put users/change-status';
export const ADD_OPERATOR_FOR_USER = 'post users/operators';
export const GET_USER_OPERATOR = 'get users/operators';
export const ADD_COLOR_TO_USER_OPERATOR = 'post users/operators/add-color';
export const GET_USER_OPERATORS = 'get users/operators/user-operator';

//Category types
export const GET_CATEGORY = 'get categories categories';
export const GET_ONE_CATEGORY = 'get categories category';
export const ADD_CATEGORY = 'post categories';
export const UPDATE_CATEGORY = 'put categories';
export const REMOVE_CATEGORY = 'delete categories';
export const GET_MAIN_CATEGORY_LOGO_BY_USER = 'get categories/category-logo/ categoryUserLogo';
export const GET_ONE_CATEGORY_LOGO_BY_USER = 'get categories/category-logo/';
export const CREATE_MAIN_CATEGORY_LOGO_BY_USER = 'post categories/category-logo/';
export const UPDATE_MAIN_CATEGORY_LOGO_BY_USER = 'put categories/category-logo/';
export const REMOVE_MAIN_CATEGORY_LOGO_BY_USER = 'delete categories/category-logo/';

//Operator types
export const GET_OPERATORS= 'get operators operators';
export const GET_OPERATOR_BY_USER = 'get operators/by-user operators';
export const GET_ONE_OPERATORS = 'get operators operator';
export const ADD_OPERATORS = 'post operators';
export const UPDATE_OPERATORS = 'put operators';
export const REMOVE_OPERATORS = 'delete operators';

//Fields types
export const GET_FIELDS = 'get fields fields';
export const GET_FIELDS_BT_CATEGORY = 'get fields/category fields';
export const GET_ONE_FIELDS = 'get fields field';
export const ADD_FIELDS = 'post fields';
export const UPDATE_FIELDS = 'put fields';
export const REMOVE_FIELDS = 'delete fields';


//Client
export const CLIENT_GET_CATEGORY_BY_USER = 'get client/category/by-user';
export const CLIENT_GET_CATEGORY_BY_ID = 'get client/category';
export const CLIENT_GET_FIELDS = 'get client/field/by-category';
export const CLIENT_ADD_CATEGORY = 'post client/category';
export const CLIENT_UPDATE_CATEGORY = 'put client/category';
export const CLIENT_REMOVE_CATEGORY = 'delete client/category';

export const CLIENT_GET_OPERATOR = 'get client/operators/';
export const CLIENT_ADD_FIELDS = 'post client/fields';
export const CLIENT_UPDATE_FIELDS = 'put client/fields';
export const CLIENT_GET_ONE_FIELD = 'get client/fields';
export const CLIENT_REMOVE_FIELD = 'delete client/fields';

export const CLIENT_GET_NEWS = 'get client/news news';
export const CLIENT_ADD_NEWS = 'post client/news';
export const CLIENT_UPDATE_NEWS = 'put client/news';
export const CLIENT_REMOVE_NEWS = 'delete client/news';

// Banners
export const GET_CLIENT_BANNER = 'get client/banners banners'
export const GET_ONE_CLIENT_BANNER = 'get client/banners'
export const ADD_BANNER = 'post client/banners'
export const UPDATE_BANNER = 'put client/banners'
export const REMOVE_BANNER = 'delete client/banners'

import {useEffect, useState} from "react";
import Req from "../../store/api";
import {CLIENT_GET_CATEGORY_BY_ID, GET_CATEGORY} from "../../store/types";

function useCategory(type = 'isParent'){
    const [categories,setCategories] = useState([]);
    useEffect(()=>{
        Req({
            type: GET_CATEGORY,
            query: {
                q: type ? type : ''
            }
        })
            .then(res=>{
                setCategories(res.data.content.rows);
            });
    },[])
        return  categories

}
function useCategoryById(id){
    const [data,setData] = useState({});
    useEffect(()=>{
       if(id){
           Req({
               type: `${CLIENT_GET_CATEGORY_BY_ID}/${id}`,
           })
               .then(res=>{
                   setData(res.data.content);
               })
       }
    },[id]);
    return data;
}
export default useCategory;
export {useCategoryById}

import {Form, Input} from "antd";

function Field(props){
    const {label, name} = props;
    return <Form.Item
                label={label}
                name={name}
                rules={[{required: true, message: `Pleas enter content`}]}>
                <Input placeholder="Input placeholder"/>
            </Form.Item>
}
export default Field;